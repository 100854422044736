import { Injectable } from '@angular/core';
import { Memoize } from '@klg/shared/utils-http';
import { StudentProfileApiService } from '@pw/api/profile';
import { BehaviorSubject, Observable } from 'rxjs';
import { StudentProfileModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StudentProfileService {
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(private profileService: StudentProfileApiService) {}

  @Memoize((masterId: string) => masterId)
  getStudentProfile(bearerToken: string, masterProfileId: string): Observable<StudentProfileModel> {
    return this.profileService.getProfile(masterProfileId, bearerToken);
  }
}
