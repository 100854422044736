<p-panel [styleClass]="styleClass" [showHeader]="showHeader">
  <ng-template pTemplate="header">
    <div class="notification-toast__header" [ngClass]="'notification-toast__header--' + severity">
      <img *ngIf="headerIcon" alt="Header icon" [ngSrc]="headerIcon" width="20" height="20" />
      <span i18n>{{ header }}</span>
      <p-button *ngIf="closable && flexDirection === 'column'" icon="pi pi-times" [link]="true" (onClick)="closeButtonClicked.emit()" />
    </div>
  </ng-template>
  <ng-container i18n>
    <span class="notification-toast__content">
      <ng-content></ng-content>
    </span>
  </ng-container>

  <ng-container *ngIf="secondaryLabel || primaryLabel">
    <ng-template pTemplate="footer">
      <pw-button
        *ngIf="secondaryLabel"
        [label]="secondaryLabel"
        [severity]="buttonSeverity"
        [secondary]="true"
        [gtmId]="secondaryGtmId"
        (buttonClicked)="secondaryClicked.emit()"
      ></pw-button>
      <pw-button
        *ngIf="primaryLabel"
        [label]="primaryLabel"
        [severity]="buttonSeverity"
        [gtmId]="primaryGtmId"
        (buttonClicked)="primaryClicked.emit()"
      ></pw-button>
    </ng-template>
  </ng-container>

  <p-button *ngIf="closable && flexDirection === 'row'" icon="pi pi-times" [link]="true" (onClick)="closeButtonClicked.emit()" />
</p-panel>
