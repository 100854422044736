<div class="notification-panel">
  <div class="notification-panel__header">
    <span class="notification-panel__header-title">Task manager</span>
    <img
      class="notification-panel__header-close"
      alt="Close icon"
      [ngSrc]="closeIcon"
      width="24"
      height="24"
      data-gtm-id="notification-panel-close"
      (click)="closePanel()"
    />
  </div>

  <ng-container *ngIf="(studentNotifications$ | async) || (studentNotificationsError$ | async); else loading">
    <div *ngIf="studentNotificationsError$ | async; else panel" class="notification-panel__no-notifications">
      <img alt="Server error" [ngSrc]="serverErrorImg" width="248" height="248" />
      <span class="notification-panel__no-notifications-title">We can't load your items</span>
      <span class="notification-panel__no-notifications-description">
        Try <a (click)="refresh()">refreshing</a> the task manager panel. If your tasks still aren't loading, please
        <a [href]="'mailto:' + supportEmail">contact us.</a>
      </span>
    </div>

    <ng-template #panel>
      <div *ngIf="studentNotifications?.length === 0; else showPanel" class="notification-panel__not-available">
        <img alt="Girl celebrating" [ngSrc]="celebrationImg" width="274" height="274" />
        <span class="notification-panel__celebration">You're all caught up</span>
      </div>

      <ng-template #showPanel>
        <div class="notification-panel__list">
          <pw-payments-due-notification *ngIf="pendingPayments" [payments]="paymentReminders.payments"></pw-payments-due-notification>

          <ng-container *ngFor="let notification of studentNotifications" [ngSwitch]="notification.name">
            <ng-container *ngSwitchCase="notificationNameEnum.enrolment_documents">
              <pw-notification-toast [severity]="toastSeverity.Primary" header="REMINDER - ENROLMENT">
                Thank you for registering with us! Please bring all the
                <a
                  href="https://downloads.kaplanpathways.com/downloads/documents-checklist"
                  target="_blank"
                  data-gtm-id="prearrival-reminder-enrolment-document-link"
                >
                  relevant documents
                </a>
                with you when you enrol.
              </pw-notification-toast>
            </ng-container>

            <ng-container *ngSwitchCase="notificationNameEnum.proof_of_accommodation">
              <pw-notification-toast [severity]="toastSeverity.Primary" header="REMINDER - ACCOMMODATION">
                <span class="semibold">Proof of accommodation</span>
                - Please bring a reference letter with you and either the latest bill or rental contract belonging to the person you are staying with. Here is
                an example of a
                <a href="https://static.kaplanpathways.com/Reference+letter+template.docx" target="_blank" data-gtm-id="prearrival-reminder-reference-letter">
                  reference letter.
                </a>
              </pw-notification-toast>
            </ng-container>

            <ng-container *ngSwitchCase="notificationNameEnum.tuition_fee">
              <pw-notification-toast
                *ngIf="notification.status !== taskItemStatusEnum.completed"
                [severity]="toastSeverity.Warning"
                [buttonSeverity]="toastSeverity.Primary"
                header="ATTENTION - TUITION FEE"
                secondaryLabel="Dismiss"
                secondaryGtmId="payment-reminder-dismiss"
                primaryLabel="Pay Tuition Fee"
                primaryGtmId="payment-reminder-pay-tuition"
                (secondaryClicked)="dismissTuitionNotification(notification.id)"
                (primaryClicked)="openOppUrl()"
              >
                If you have already paid, you do not need to do anything. Please pay your tuition fee, if you have not paid it already. You can then access your
                timetable and course materials, and start attending classes.
              </pw-notification-toast>
            </ng-container>

            <ng-container *ngSwitchCase="notificationNameEnum.pre_arrival_tasks">
              <hr />
              <pw-pre-arrival-tasks-notification [preArrivalTasksNotification]="notification"></pw-pre-arrival-tasks-notification>
              <hr />
            </ng-container>
          </ng-container>

          <pw-notification-info-section *ngIf="infoNotifications?.length > 0" [infoNotifications]="infoNotifications"></pw-notification-info-section>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <hr />
    <ng-container *ngFor="let item of [].constructor(10)">
      <p-skeleton width="20rem" styleClass="mb-2" />
    </ng-container>
  </ng-template>
</div>
