import { inject, Injectable } from '@angular/core';
import { SATimetableApiService } from '@pw/api/profile';
import { catchError, Observable, of } from 'rxjs';
import { StudentTimetable } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StudentTimetableService {
  private readonly timetableApiService = inject(SATimetableApiService);

  getTimetable(bearerToken: string, masterProfileId: string): Observable<StudentTimetable> {
    return this.timetableApiService.getTimetable(masterProfileId, bearerToken).pipe(
      catchError((error: Error) => {
        console.error('Error occurred while getting the student timetable', error);
        return of({ timetable: [], modifiedAt: null });
      }),
    );
  }
}
