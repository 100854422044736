import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';

@Component({
  standalone: true,
  imports: [CommonModule, BreadcrumbModule, DialogModule],
  selector: 'pw-popup-shell',
  templateUrl: './popup-shell.component.html',
  styleUrls: ['./popup-shell.component.scss'],
})
export class PopupShellComponent {
  @Input() isVisible = true;
  @Input() popupTitle = '';
}
