import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, Component, inject, Input, OnInit } from '@angular/core';
import { AttendanceSession } from '@pw/profile-data-access';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { AttendanceTableComponent } from '../../atoms';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { take, tap } from 'rxjs';

@Component({
  selector: 'pw-attendance-absences-tabs',
  standalone: true,
  imports: [CommonModule, TabViewModule, AttendanceTableComponent, NgOptimizedImage, TooltipModule],
  templateUrl: './attendance-absences-tabs.component.html',
  styleUrls: ['./attendance-absences-tabs.component.scss'],
})
export class AttendanceAbsencesTabsComponent implements AfterViewInit, OnInit {
  @Input() absences: AttendanceSession[] = [];
  @Input() unposted: AttendanceSession[] = [];

  infoIconPath = '/assets/icons/info.svg';
  infoIconTooltip = 'Attendance or absences from classes where attendance lists haven’t been uploaded or updated yet.';
  isTouchDevice = false;

  private readonly document = inject(DOCUMENT);
  private readonly breakpointObserverService = inject(BreakpointObserverService);

  ngAfterViewInit(): void {
    const emptyTab = this.document.querySelector('.attendance-absences-tabs__empty-tab');
    if (emptyTab) {
      emptyTab.removeChild(emptyTab.firstChild);
    }
  }

  ngOnInit(): void {
    this.breakpointObserverService.isTouchDevice$
      .pipe(
        take(1),
        tap((isTouchDevice) => (this.isTouchDevice = isTouchDevice)),
      )
      .subscribe();
  }
}
