import { Component, inject, OnInit } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';
import { take, tap } from 'rxjs';

@Component({
  standalone: false,
  template: '',
})
export class SignInComponent implements OnInit {
  private readonly authService = inject(PathwaysAuthService);

  ngOnInit(): void {
    this.authService
      .initialize()
      .pipe(
        take(1),
        tap(() => this.authService.login()),
      )
      .subscribe();
  }
}
