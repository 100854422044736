import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { User } from '@pw/account/auth';
import { colleges, StudentFeatureEnum } from '@pw/profile-data-access';
import { combineLatest, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../services';

@Component({
  selector: 'pw-private-page-main',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './private-page-main.component.html',
  styleUrls: ['./private-page-main.component.scss'],
})
export class PrivatePageMainComponent implements OnInit, OnChanges, OnDestroy {
  @Input() collegeCode = '';
  @Input() pageTitle = '';
  @Input() pageSubtitle = '';

  collegeName = '';
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  isFlexColumn = false;
  currentUser: User = {} as User;
  showLogo = false;

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
  }

  ngOnInit(): void {
    this.currentUser = this.activatedRoute.snapshot?.data?.currentUser || new User({});

    combineLatest([this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isTablet, isPanelOpen]) => (this.isFlexColumn = isTablet && isPanelOpen)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.showLogo = Boolean(
      this.collegeCode && this.collegeName && this.currentUser.features.find((feature) => feature.id === StudentFeatureEnum.college_logo)?.enabled,
    );
  }

  ngOnChanges({ collegeCode }: SimpleChanges): void {
    if (collegeCode?.currentValue) {
      this.collegeName = colleges.find((college) => college.code === this.collegeCode)?.name;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
