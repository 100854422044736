<div class="section-shell">
  <div class="section-shell__header" [class.section-shell__header--anchor]="headerIsAnchor">
    <a *ngIf="headerIsAnchor; else headerIsSpan" class="section-shell__header-anchor" [attr.data-gtm-id]="homeAnchorGtmEventName" (click)="goToSection()" i18n>
      {{ sectionHeader }}
      <img alt="Chevron right icon" [ngSrc]="chevronRightImg" width="16" height="16" />
    </a>
    <ng-template #headerIsSpan>
      <span>{{ sectionHeader }}</span>
    </ng-template>
  </div>
  <ng-content></ng-content>
</div>
