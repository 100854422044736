import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { PathwaysAuthService, PathwaysBroadcastSignOutService, sessionDurationHours, StudentFeatureEnum, User } from '@pw/account/auth';
import { CivicCookiesWithOptionalsService } from '@pw/shared/cookies';
import { environment, isPrivateRoute, PathwaysAccountRoutes } from '@pw/shared/environment';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { SignOutType } from '@pw/shared/types';
import { hideLoadingOverlay, showLoadingOverlay } from '@pw/shared/ui';
import { Subscription } from 'rxjs';
import { throttle } from 'throttle-debounce';

@Component({
  selector: 'pw-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private currentRoute: string;
  private isSessionExpired = false;
  private renewSessionCallback: () => void;

  private readonly router = inject(Router);
  private readonly googleTagManagerService = inject(PathwaysGoogleTagManagerService);
  private readonly cookieService = inject(CivicCookiesWithOptionalsService);
  private readonly broadcastSignOut = inject(PathwaysBroadcastSignOutService);
  private readonly authService = inject(PathwaysAuthService);
  private readonly msalBroadcastService = inject(MsalBroadcastService);
  private readonly activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        showLoadingOverlay();
      }
      if (event instanceof NavigationEnd && event.url !== `/${PathwaysAccountRoutes.SIGN_IN}`) {
        hideLoadingOverlay();
        this.cookieService.load();
        window.onbeforeunload = () => {
          this.cleanup();
        };
      }
      if (event instanceof NavigationEnd) {
        let currentRoute = this.activatedRoute;
        // Traverse to the deepest child to access its data
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }

        const currentUser = currentRoute.snapshot.data?.currentUser as User;
        const attendanceFeature = currentUser?.features.find((feature) => feature.id === StudentFeatureEnum.attendance);
        const customEventData = {
          preArrivalRequired: !!currentUser?.preArrival?.required,
          attendanceEnabled: environment.FEATURE_FLAGS.ATTENDANCE_OVERVIEW_ENABLED && !!attendanceFeature?.enabled,
        };

        this.currentRoute = event.url;
        this.googleTagManagerService.pushTag({
          event: 'custom-page-view',
          pageName: event.url,
          ...(currentUser && customEventData),
        });
      }
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.msalBroadcastService.msalSubject$.subscribe(({ eventType }: EventMessage) => {
        switch (eventType) {
          case EventType.ACQUIRE_TOKEN_START:
            // Mark it for logout later on ACQUIRE_TOKEN_SUCCESS
            this.isSessionExpired = this.sessionExpired();
            break;

          case EventType.ACQUIRE_TOKEN_SUCCESS:
            if (this.isSessionExpired) {
              this.authService.logout(SignOutType.EXPIRED);
            }
            break;

          case EventType.ACQUIRE_TOKEN_FAILURE:
            this.authService.logout(SignOutType.EXPIRED);
            break;

          default:
            break;
        }
      }),
    );

    // Redirect to sign-out page when other tab finished session
    this.subscriptions.add(
      this.broadcastSignOut.onSignOut$.subscribe((signOutType: SignOutType) => {
        if (isPrivateRoute(this.currentRoute)) {
          this.router.navigate(['/', PathwaysAccountRoutes.SIGN_OUT, signOutType ?? SignOutType.DEFAULT]);
        }
      }),
    );

    // add listener responsible for extending session
    this.addClickListener();
  }

  ngOnDestroy(): void {
    this.cleanup();
  }

  /**
   * Session is considered expired if sessionDurationHours has elapsed since iat (issued at)
   */
  private sessionExpired(): boolean {
    const activeAccount = this.authService.getActiveAccount();
    if (!activeAccount?.idTokenClaims?.iat) {
      return true;
    }

    const lastTokenAcquisition = Number(activeAccount?.idTokenClaims?.iat) * 1000;
    const nowMilliseconds = new Date().getTime();
    const sessionDurationMilliseconds = sessionDurationHours * 60 * 60 * 1000;
    return nowMilliseconds - lastTokenAcquisition > sessionDurationMilliseconds;
  }

  private cleanup(): void {
    this.cookieService.disconnect();
    this.subscriptions.unsubscribe();
    document.body.removeEventListener('click', this.renewSessionCallback);
  }

  private addClickListener() {
    this.renewSessionCallback = () => this.authService.ssoSilent();
    document.body.addEventListener('click', throttle(30000, this.renewSessionCallback));
  }
}
