import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { StudentNotificationService } from '@pw/notification-data-access';
import { StudentAttendanceService, StudentProfileService, StudentTimetableService } from '@pw/profile-data-access';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { jwtDecode } from 'jwt-decode';
import { combineLatest, EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, take } from 'rxjs/operators';
import { User } from '../models/user.model';
import { PathwaysAuthService } from '../services/auth.service';

export const currentUserResolver: ResolveFn<User> = (route: ActivatedRouteSnapshot) => {
  const authService = inject(PathwaysAuthService);
  const profileService = inject(StudentProfileService);
  const notificationService = inject(StudentNotificationService);
  const attendanceService = inject(StudentAttendanceService);
  const timetableService = inject(StudentTimetableService);
  const router = inject(Router);

  const { masterProfileId, email } = getMasterProfileId(authService, route);

  if (!masterProfileId) {
    return of(new User({}));
  }

  return from(authService.initialize()).pipe(
    concatMap(() =>
      authService.ssoSilent().pipe(
        take(1),
        switchMap(() =>
          combineLatest([
            profileService.getStudentProfile(authService.getBearerToken(), masterProfileId),
            notificationService.getStudentNotifications(authService.getBearerToken(), masterProfileId),
            attendanceService.getAttendances(authService.getBearerToken(), masterProfileId),
            timetableService.getTimetable(authService.getBearerToken(), masterProfileId).pipe(catchError(() => of([]))),
          ]),
        ),
        map(([studentProfile]) => User.createUserFromStudentProfile(studentProfile, email)),
        catchError((error) => {
          const navigationCommands = ['/', PathwaysAccountRoutes.ERROR];
          if (error instanceof HttpErrorResponse && error.status) {
            navigationCommands.push(error.status.toString());
          }
          void router.navigate(navigationCommands, { skipLocationChange: true });
          return EMPTY;
        }),
      ),
    ),
  );
};

const getMasterProfileId = (authService: PathwaysAuthService, route: ActivatedRouteSnapshot): { masterProfileId: string; email: string } => {
  let masterProfileId: string;
  let email: string;

  if (authService.getActiveAccount()) {
    masterProfileId = authService.getActiveAccountMasterProfileId();
    email = authService.getActiveAccountEmail();
  } else if (route.fragment) {
    try {
      // TODO Validate the id_token to make sure its from azure
      const fragments = route.fragment.split('=');
      const token = fragments[fragments.indexOf('id_token') + 1];
      authService.setIdToken(token);

      const decodedToken = jwtDecode(token);
      masterProfileId = decodedToken['extension_MasterProfileId'];
      email = decodedToken['emails'][0];
    } catch (e) {
      console.log('Error decoding id_token', e);
    }
  }

  return { masterProfileId, email };
};
