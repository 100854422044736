<p-tabView>
  <p-tabPanel header="Absences" headerStyleClass="attendance-absences-tabs__absences-tab">
    <pw-attendance-table [sessions]="absences"></pw-attendance-table>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div class="attendance-absences-tabs__unposted-header">
        <span class="p-tabview-title" i18n>Unposted</span>

        <ng-container *ngIf="isTouchDevice; else forDesktop">
          <!-- PrimeNg does not support touch event for tooltip so this is the suggested work around -->
          <!-- https://github.com/primefaces/primeng/issues/8454#issuecomment-995845656 -->
          <span
            [tabindex]="0"
            style="outline: 0"
            [pTooltip]="infoIconTooltip"
            [tooltipEvent]="'focus'"
            tooltipPosition="top"
            tooltipStyleClass="pw-tooltip"
            #hint
            (click)="$event.stopPropagation(); hint.focus()"
          >
            <img alt="Info icon" class="attendance-absences-tabs__unposted-header-icon" [ngSrc]="infoIconPath" width="18" height="18" />
          </span>
        </ng-container>
        <ng-template #forDesktop>
          <img
            alt="Info icon"
            class="attendance-absences-tabs__unposted-header-icon"
            [ngSrc]="infoIconPath"
            width="18"
            height="18"
            [pTooltip]="infoIconTooltip"
            tooltipPosition="top"
            tooltipStyleClass="pw-tooltip"
          />
        </ng-template>
      </div>
    </ng-template>
    <pw-attendance-table
      [sessions]="unposted"
      noSessionsMessage="No data available. Your teachers have updated all your attendance records."
    ></pw-attendance-table>
  </p-tabPanel>
  <p-tabPanel headerStyleClass="attendance-absences-tabs__empty-tab"></p-tabPanel>
</p-tabView>
