import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PathwaysAccountRoutes } from '@pw/shared/environment';

@Component({
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  selector: 'pw-section-shell',
  templateUrl: './section-shell.component.html',
  styleUrls: ['./section-shell.component.scss'],
})
export class SectionShellComponent implements AfterViewInit {
  @Input() sectionHeader = '';
  @Input() headerIsAnchor = true;
  @Input() homeAnchorGtmEventName = '';
  @Input() sectionRoute!: PathwaysAccountRoutes;

  chevronRightImg = '/assets/icons/chevron-right.svg';

  private readonly router = inject(Router);

  async goToSection() {
    await this.router.navigate(['/', this.sectionRoute]);
  }

  ngAfterViewInit(): void {
    if (!this.headerIsAnchor) {
      this.homeAnchorGtmEventName = '';
      this.sectionRoute = undefined;
    }
  }
}
