import { inject, Injectable } from '@angular/core';
import { environment } from '@pw/shared/environment';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { getBaseCivicCookiesConfig } from '../config';
import { CivicConfig, CivicPurposeObject } from '../types';
import { CivicCookiesService } from './civic-cookies.service';
import { PathwaysAuthService } from '@pw/account/auth';

declare const window: Window & {
  clarity: (...args: any[]) => void;
  firstClarityScriptResolver: (r: boolean) => void;
  firstClarityScriptPromise: Promise<boolean>;
  clarityInitScript: HTMLScriptElement | undefined;
};

const analyticsConsentGivenTag = {
  event: 'analytics_consent_given',
  civic_cookies_analytics: 'consent_given',
};

const analyticsConsentRevokedTag = {
  event: 'analytics_consent_revoked',
  civic_cookies_analytics: 'consent_revoked',
};

@Injectable({
  providedIn: 'root',
})
export class CivicCookiesWithOptionalsService extends CivicCookiesService {
  private readonly googleTagManagerService = inject(PathwaysGoogleTagManagerService);
  private readonly authService = inject(PathwaysAuthService);

  private analyticsCategoryName = 'performance';

  protected getCookieConfig(): CivicConfig {
    const baseCookieConfig: CivicConfig = getBaseCivicCookiesConfig(environment.PRIVACY_URL);
    return {
      ...baseCookieConfig,
      // Send consent on Load
      onLoad: () => {
        this.pushAnalyticsConsentToTagManager();
        this.addClarityScript();
        this.deleteOptionalCookiesIfRevoked();
      },
      // Extend performance cookies onAccept and onRevoke
      optionalCookies: baseCookieConfig.optionalCookies.map((optionalCookiesConfig: CivicPurposeObject) => {
        return optionalCookiesConfig.name === this.analyticsCategoryName
          ? {
              ...optionalCookiesConfig,
              onAccept: () => {
                this.pushAnalyticsConsentToTagManager(true);
                this.setClarityConsent(true);
                optionalCookiesConfig.onAccept();
              },
              onRevoke: () => {
                this.pushAnalyticsConsentToTagManager(false);
                this.setClarityConsent(false);
                optionalCookiesConfig.onRevoke();
              },
            }
          : optionalCookiesConfig;
      }),
    };
  }

  private getAnalyticsConsent(): boolean {
    return this.getOptionalCookiesConsent(this.analyticsCategoryName);
  }

  private pushAnalyticsConsentToTagManager(consentGiven: boolean = this.getAnalyticsConsent()): void {
    this.googleTagManagerService.pushTag(consentGiven ? analyticsConsentGivenTag : analyticsConsentRevokedTag);
  }

  private async setClarityConsent(consentGiven: boolean = this.getAnalyticsConsent()): Promise<void> {
    if (!window.clarity) {
      await this.addClarityScript(consentGiven);
    }

    window.clarity('consent', consentGiven);
    window.clarity(consentGiven ? 'start' : 'stop');
  }

  /*
   * INFO: Pay attention to clarityLoaderScript.text. THey have added 2 lines
   * window.clarityInitScript = t;
   * window.firstClarityScriptResolver();
   * Those lines are important for awaiting for script to load.
   */
  private async addClarityScript(optionalConsentGiven: boolean = this.getAnalyticsConsent()): Promise<HTMLScriptElement | string | Event> {
    if (!optionalConsentGiven) {
      return;
    }

    window.firstClarityScriptPromise = new Promise((resolve) => {
      window.firstClarityScriptResolver = resolve;
    });

    const clarityLoaderScript: HTMLScriptElement = document.createElement('script');
    clarityLoaderScript.text = `
      (function(c,l,a,r,i,t,y) {
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src='https://www.clarity.ms/tag/'+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        window.clarityInitScript = t;
        window.firstClarityScriptResolver();
      })(window, document, 'clarity', 'script', '${environment.CLARITY_HASH}');
    `;
    clarityLoaderScript.type = 'text/javascript';
    document.head.appendChild(clarityLoaderScript);
    await window.firstClarityScriptPromise;
    const clarityScript = await this.promiseScriptLoad(window.clarityInitScript);

    const masterProfileId = this.authService.getActiveAccountMasterProfileId() || '';
    window.clarity('identify', masterProfileId);
    return clarityScript;
  }

  private async promiseScriptLoad(script: HTMLScriptElement): Promise<HTMLScriptElement | string | Event> {
    return new Promise((resolve) => {
      script.onload = () => resolve(script);
      script.onerror = (e) => {
        console.error(e);
        resolve(e);
      };
      document.head.appendChild(script);
    });
  }
}
