<p-tabView>
  <p-tabPanel header="Absences" headerStyleClass="attendance-absences-tabs__absences-tab">
    <pw-attendance-table [sessions]="absences"></pw-attendance-table>
  </p-tabPanel>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <div class="attendance-absences-tabs__unposted-header">
        <span class="p-tabview-title" i18n>Unposted</span>
        <img
          alt="Info icon"
          class="attendance-absences-tabs__unposted-header-icon"
          [ngSrc]="infoIconPath"
          width="18"
          height="18"
          [pTooltip]="infoIconTooltip"
          tooltipPosition="top"
          tooltipStyleClass="pw-tooltip"
        />
      </div>
    </ng-template>
    <pw-attendance-table
      [sessions]="unposted"
      noSessionsMessage="No data available. Your teachers have updated all your attendance records."
    ></pw-attendance-table>
  </p-tabPanel>
  <p-tabPanel headerStyleClass="attendance-absences-tabs__empty-tab"></p-tabPanel>
</p-tabView>
