import { InjectionToken } from '@angular/core';
import { LogLevel } from '@azure/msal-browser';
import { AzureB2cCustomPolicyFlows } from './azure-b2c-custom-policy-flows.enum';
import { StudentApps } from './student-apps.enum';

export const PATHWAYS_ENVIRONMENT = new InjectionToken<PathwaysEnvironment>('PATHWAYS_ENVIRONMENT');

export enum INTERNAL_API_URL {
  LOCAL = 'http://localhost:3000/dev',
  DEV = 'https://wjt9el21e9-vpce-008f6b0b4b150a266.execute-api.ap-southeast-1.amazonaws.com/dev',
  STAGE = 'https://gjummail56-vpce-084d53e0399d685b6.execute-api.eu-west-1.amazonaws.com/stage',
  PROD = 'https://q5r2byv8ud-vpce-05bb6e00f881786d9.execute-api.eu-west-1.amazonaws.com/prod',
}

export enum API_URL {
  LOCAL = 'http://localhost:3000/local',
  DEV = 'https://api.pathways-dev.kapintdc.com',
  STAGE = 'https://api.pathways-stage.kapintdc.com',
  PROD = 'https://api.kaplanpathways.com',
}

type PreArrivalTaskConfig = {
  name: string;
  url: string;
};

export type FeatureFlags = {
  ATTENDANCE_OVERVIEW_ENABLED: boolean;
  PAYMENTS_NOTIFICATION_ENABLED: boolean;
  TIME_TABLER_ENABLED: boolean;
  TIME_TABLER_SECTION_ENABLED: boolean;
};

export interface PathwaysEnvironment {
  production: boolean;
  API_BASE_URL: API_URL;
  INTERNAL_API_BASE_URL: INTERNAL_API_URL;
  B2C_DOMAIN: string;
  B2C_BASE_PATH: string;
  B2C_CLIENT_ID: string;
  B2C_LOG_LEVEL: LogLevel;
  B2C_CUSTOM_POLICIES: Record<AzureB2cCustomPolicyFlows, string>;
  STUDENT_APPS_URLS: Record<StudentApps, string>;
  PORTAL_PATH: string;
  SUPPORT_EMAIL: string;
  CLARITY_HASH: string;
  PRIVACY_URL: string;
  GOOGLE_TAG_MANAGER: {
    ID: string;
    AUTH: string;
    ENV: string;
  };
  BROADCAST_CHANNEL: {
    SIGN_OUT: string;
  };
  HESA: {
    FORM_URL: string;
    STUDENT_ID_FIELD_ID: string;
  };
  AZURE_AD_APPLICATION_ID: string;
  ENV: string;
  MAINTENANCE_MODE: boolean;
  PRE_ARRIVAL_CHECKLIST_TASKS: Record<string, PreArrivalTaskConfig[]>;
  PRIVATE_ACCOMMODATION_URL: string;
  FEATURE_FLAGS: FeatureFlags;
}
