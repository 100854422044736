import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pw-section-shell',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section-shell.component.html',
  styleUrls: ['./section-shell.component.scss'],
})
export class SectionShellComponent {
  @Input() header = '';
}
