import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { StudentTimetableService, TimetableSession } from '@pw/profile-data-access';
import { Size } from '@pw/shared/types';
import { filter, tap } from 'rxjs';
import { PwButtonComponent } from '../../atoms';
import { PopupShellComponent } from '../popup-shell/popup-shell.component';

@Component({
  standalone: true,
  imports: [CommonModule, PopupShellComponent, PwButtonComponent],
  selector: 'pw-timetable-popup',
  templateUrl: './timetable-popup.component.html',
  styleUrl: './timetable-popup.component.scss',
})
export class TimetablePopupComponent implements OnInit {
  selectedEvent!: EventImpl;
  selectedEventProps: Partial<TimetableSession>;

  protected readonly buttonSize = Size;

  private readonly timetableService = inject(StudentTimetableService);

  ngOnInit(): void {
    this.timetableService.selectedEvent$
      .pipe(
        filter((event) => !!event),
        tap((event) => {
          this.selectedEvent = event;
          const extendedProps = event.extendedProps as Partial<TimetableSession>;
          this.selectedEventProps = {
            venue: extendedProps.venue ?? '',
            presenter: extendedProps.presenter ?? '',
            meetingUrl: extendedProps.meetingUrl ?? '',
          };
        }),
      )
      .subscribe();
  }

  closePopup() {
    this.selectedEvent = null;
    this.timetableService.setSelectedEvent(null);
    this.timetableService.setEventOpen(false);
  }
}
