import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { StudentTimetable, StudentTimetableService } from '@pw/profile-data-access';
import { Observable } from 'rxjs';
import { SectionShellComponent, TimetableComponent } from '../../molecules';
import { PathwaysAuthService } from '@pw/account/auth';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  standalone: true,
  imports: [CommonModule, SectionShellComponent, TimetableComponent, SkeletonModule],
  selector: 'pw-timetable-section',
  templateUrl: './timetable-section.component.html',
  styleUrl: './timetable-section.component.scss',
})
export class TimetableSectionComponent implements OnInit {
  studentTimetable$: Observable<StudentTimetable>;

  private readonly timetableService = inject(StudentTimetableService);
  private readonly authService = inject(PathwaysAuthService);

  ngOnInit() {
    const bearerToken = this.authService.getBearerToken();
    const masterProfileId = this.authService.getActiveAccountMasterProfileId();
    this.studentTimetable$ = this.timetableService.getTimetable(bearerToken, masterProfileId);
  }
}
