import { AccommodationApiDto, AvailableAppApiDto, EnrollmentEntityApiDto, PreArrivalApiDto, StudentFeaturesEntityApiDto } from '@pw/api/profile';
import { StudentProfileModel } from '@pw/profile-data-access';

export type AvailableApp = AvailableAppApiDto;
export type PreArrival = PreArrivalApiDto;
export type Accommodation = AccommodationApiDto;
export type StudentFeature = StudentFeaturesEntityApiDto;
export type StudentFeatureEnum = StudentFeaturesEntityApiDto.IdApiDtoEnum;
export const StudentFeatureEnum = StudentFeaturesEntityApiDto.IdApiDtoEnum;
export type Enrollment = EnrollmentEntityApiDto;

export interface UserDataInterface {
  masterProfileId: string;
  studentId: string;
  name: string;
  surname: string;
  dateOfBirth: string;
  email: string;
  profileEmail: string;
  college: string;
  ediFormCompletionDate: string;
  ediDataRequired: boolean;
  apps: AvailableApp[];
  preArrival: PreArrival;
  accommodation: Accommodation | undefined;
  enrollment: Enrollment;
  features: StudentFeature[];
}

export class User {
  masterProfileId: string;
  studentId: string;
  name: string;
  surname: string;
  dateOfBirth: Date;
  email: string;
  profileEmail: string;
  college: string;
  ediDataRequired: boolean;
  ediFormCompletionDate: Date | null;

  fullStudentId: string;
  fullName: string;
  initials: string;
  apps: AvailableApp[];
  preArrival: PreArrival;
  accommodation: Accommodation | undefined;
  enrollment: Enrollment;
  features: StudentFeature[];

  constructor(data: Partial<UserDataInterface>) {
    this.masterProfileId = data.masterProfileId;
    this.studentId = data.studentId;
    this.fullStudentId = `UK - ${data.studentId}`;
    this.name = data.name;
    this.surname = data.surname;
    this.fullName = `${data.name} ${data.surname}`.trim();
    this.initials = `${data.name?.slice(0, 1)}${data.surname?.slice(0, 1)}`.toUpperCase();
    this.dateOfBirth = new Date(data.dateOfBirth);
    this.email = data.email;
    this.profileEmail = data.profileEmail;
    this.college = data.college;
    this.apps = data.apps || [];
    this.ediFormCompletionDate = data.ediFormCompletionDate ? new Date(data.ediFormCompletionDate) : null;
    this.ediDataRequired = data.ediDataRequired;
    this.preArrival = data.preArrival || { required: false };
    this.accommodation = data.accommodation;
    this.enrollment = data.enrollment || { isActive: false };
    this.features = data.features || [];
  }

  static createUserFromStudentProfile(studentProfile: StudentProfileModel, email: string) {
    const user = new User({});
    user.masterProfileId = studentProfile.masterProfileId;
    user.studentId = studentProfile.kssId;
    user.fullStudentId = `UK - ${studentProfile.kssId}`;
    user.name = studentProfile.firstName;
    user.surname = studentProfile.lastName;
    user.fullName = `${studentProfile.firstName} ${studentProfile.lastName}`.trim();
    user.initials = `${studentProfile.firstName?.slice(0, 1)}${studentProfile.lastName?.slice(0, 1)}`.toUpperCase();
    user.dateOfBirth = new Date(studentProfile.dateOfBirth);
    user.email = email;
    user.profileEmail = studentProfile.profileEmail;
    user.college = studentProfile.college;
    user.apps = studentProfile.apps || [];
    user.ediFormCompletionDate = studentProfile.ediFormCompletionDate ? new Date(studentProfile.ediFormCompletionDate) : null;
    user.ediDataRequired = studentProfile.ediDataRequired;
    user.preArrival = studentProfile.preArrival;
    user.accommodation = studentProfile.accommodation;
    user.enrollment = studentProfile.enrollment;
    user.features = studentProfile.features || [];

    return user;
  }
}
