import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  PatchNotificationRequest,
  PatchNotificationRequestActionEnum,
  StudentNotification,
  StudentNotificationEnum,
  StudentNotificationNameEnum,
  StudentNotificationService,
  TaskItemStatusEnum,
} from '@pw/notification-data-access';
import { environment } from '@pw/shared/environment';
import { Severity } from '@pw/shared/types';
import { filter, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { NotificationToastComponent } from '../../molecules';
import { PaymentsDueNotificationComponent } from '../payments-due-notification/payments-due-notification.component';
import { NotificationInfoSectionComponent } from './notification-info-section/notification-info-section.component';
import { PreArrivalTasksNotificationComponent } from './pre-arrival-tasks/pre-arrival-tasks-notification.component';
import { SkeletonModule } from 'primeng/skeleton';
import { PathwaysAuthService } from '@pw/account/auth';

@Component({
  selector: 'pw-notification-panel',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    PreArrivalTasksNotificationComponent,
    NotificationToastComponent,
    NotificationInfoSectionComponent,
    PaymentsDueNotificationComponent,
    SkeletonModule,
  ],
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  @Input() studentNotifications: StudentNotification[] = [];

  studentNotificationsError$: Observable<string>;
  studentNotifications$: Observable<StudentNotification[]>;
  paymentReminders: StudentNotification;
  pendingPayments = 0;
  infoNotifications: StudentNotification[] = [];

  closeIcon = 'assets/icons/close.svg';
  celebrationImg = 'assets/illustrations/celebration.svg';
  serverErrorImg = 'assets/illustrations/server-error.png';
  notificationNameEnum = StudentNotificationNameEnum;
  taskItemStatusEnum = TaskItemStatusEnum;
  toastSeverity = Severity;

  readonly supportEmail = environment.SUPPORT_EMAIL;

  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly notificationService = inject(StudentNotificationService);
  private readonly authService = inject(PathwaysAuthService);
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.studentNotificationsError$ = this.notificationService.studentNotificationsError$;
    this.studentNotifications$ = this.notificationService.studentNotifications$;
    this.paymentReminders = this.notificationService.getPaymentReminders();
    this.pendingPayments = this.notificationService.getPendingPayments();
  }

  ngOnInit() {
    this.studentNotifications$
      .pipe(
        filter(Boolean),
        tap((studentNotifications) => {
          this.infoNotifications = studentNotifications.filter((notification) => notification.type === StudentNotificationEnum.info);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closePanel() {
    this.notificationPanelService.togglePanel(false);
  }

  refresh() {
    window.location.reload();
  }

  dismissTuitionNotification(notificationId: string) {
    const payload: PatchNotificationRequest = { action: PatchNotificationRequestActionEnum.complete };
    const masterProfileId = this.authService.getActiveAccountMasterProfileId();
    const bearerToken = this.authService.getBearerToken();

    this.notificationService
      .updateNotification(bearerToken, masterProfileId, notificationId, payload)
      .pipe(
        take(1),
        switchMap(() => this.notificationService.getStudentNotifications(bearerToken, masterProfileId)),
      )
      .subscribe();
  }

  openOppUrl() {
    window.open(environment.STUDENT_APPS_URLS.OPP, '_blank');
  }
}
