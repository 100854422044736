import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { AttendanceStats, colleges, StudentAttendanceService } from '@pw/profile-data-access';
import { environment, PathwaysAccountRoutes } from '@pw/shared/environment';
import { GtmEventNames } from '@pw/shared/types';
import { combineLatest, Observable, skip, tap } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { PwButtonComponent } from '../../atoms';
import { AttendanceCardComponent, SectionShellComponent } from '../../molecules';
import { SkeletonModule } from 'primeng/skeleton';
import { PathwaysAuthService } from '@pw/account/auth';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [CommonModule, AttendanceCardComponent, PwButtonComponent, SectionShellComponent, SkeletonModule],
  selector: 'pw-attendance-overview',
  templateUrl: './attendance-overview.component.html',
  styleUrls: ['./attendance-overview.component.scss'],
})
export class AttendanceOverviewComponent implements OnInit, OnChanges {
  @Input() college = '';

  shortCard = false;
  absenceFormUrl = '';
  lastModifiedAt: Date | null = null;
  isLoading = true;

  attendanceStats$: Observable<AttendanceStats>;
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  isAttendanceDataAvailable = false;
  attendancesError$: Observable<string>;

  protected readonly gtmEventNames = GtmEventNames;
  protected readonly pathwaysRoutes = PathwaysAccountRoutes;
  protected readonly supportEmail = environment.SUPPORT_EMAIL;

  private readonly router = inject(Router);
  private readonly studentAttendanceService = inject(StudentAttendanceService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly authService = inject(PathwaysAuthService);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.attendanceStats$ = this.studentAttendanceService.attendanceStats$;
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
    this.attendancesError$ = this.studentAttendanceService.attendancesError$;
  }

  ngOnInit(): void {
    combineLatest([this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isTablet, isPanelOpen]) => (this.shortCard = isTablet && isPanelOpen)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    const bearerToken = this.authService.getBearerToken();
    const masterProfileId = this.authService.getActiveAccountMasterProfileId();
    this.studentAttendanceService
      .getAttendances(bearerToken, masterProfileId)
      .pipe(
        tap(() => (this.isLoading = false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.attendanceStats$
      .pipe(
        tap(({ stats, lastModifiedAt }) => {
          this.isAttendanceDataAvailable = !!stats?.length;
          this.lastModifiedAt = lastModifiedAt;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.attendancesError$
      .pipe(
        skip(1),
        tap(() => (this.isLoading = false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnChanges({ college }: SimpleChanges): void {
    if (college?.currentValue) {
      this.absenceFormUrl = colleges.find((college) => college.code === this.college)?.absenceFormUrl;
    }
  }

  goToAttendanceOverview() {
    this.router.navigate(['/', PathwaysAccountRoutes.ATTENDANCE_OVERVIEW]);
  }

  refresh() {
    window.location.reload();
  }
}
