export const emailValidator = (email: string): boolean => {
  if (!email) return false;

  const maxlength = 80;

  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const trimmedEmail = email.trim();

  return trimmedEmail.length <= maxlength && emailRegex.test(trimmedEmail);
};
