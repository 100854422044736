import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import {
  AttendanceSession,
  AttendanceStat,
  AttendanceSummary,
  AttendanceTypeEnum,
  colleges,
  createDateFromString,
  StudentAttendanceService,
} from '@pw/profile-data-access';
import { GtmEventNames } from '@pw/shared/types';
import { subDays } from 'date-fns';
import { combineLatest, distinctUntilChanged, filter, merge, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationPanelService } from '../../../services';
import { AttendanceTableComponent, PwButtonComponent } from '../../atoms';
import { AttendanceAbsencesTabsComponent, AttendanceCardComponent, AttendanceTabsComponent, SectionShellComponent } from '../../molecules';
import { environment } from '@pw/shared/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pw-attendance-absences',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    AttendanceCardComponent,
    PwButtonComponent,
    AttendanceTabsComponent,
    AttendanceTableComponent,
    AttendanceAbsencesTabsComponent,
    SectionShellComponent,
  ],
  templateUrl: './attendance-detail.component.html',
  styleUrls: ['./attendance-detail.component.scss'],
})
export class AttendanceDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() college = '';

  yesterday = subDays(new Date(), 1);
  currentTab: string = AttendanceTypeEnum.monthly;
  attendanceStats: AttendanceStat[] = [];
  absences: AttendanceSession[] = [];
  unposted: AttendanceSession[] = [];
  shortCard = false;
  infoImg = '/assets/icons/info.svg';
  gtmEventNames = GtmEventNames;
  absenceFormUrl = '';
  serviceEmail = '';

  filters$!: Observable<Date[]>;
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  attendancesError$: Observable<string>;
  readonly supportEmail = environment.SUPPORT_EMAIL;

  private destroy$ = new Subject<void>();

  private readonly studentAttendanceService = inject(StudentAttendanceService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly route = inject(ActivatedRoute);

  constructor() {
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
    this.attendancesError$ = this.studentAttendanceService.attendancesError$;
  }

  ngOnInit(): void {
    this.updateCurrentTab();
    this.studentAttendanceService.attendanceStats$?.pipe(takeUntil(this.destroy$)).subscribe((stats) => (this.attendanceStats = stats));

    merge(this.studentAttendanceService.currentMonthlyFilters$, this.studentAttendanceService.currentWeeklyFilters$)
      .pipe(
        distinctUntilChanged(),
        filter(() => this.currentTab !== AttendanceTypeEnum.overall),
        map(() =>
          this.currentTab === AttendanceTypeEnum.monthly
            ? this.studentAttendanceService.currentMonthlyFilters$
            : this.studentAttendanceService.currentWeeklyFilters$,
        ),
        tap((newFilters$) => this.filterRows(newFilters$)),
      )
      .subscribe();

    this.breakpointObserverService.isMobile$
      .pipe(
        map((isMobile) => {
          this.updateCurrentTab(isMobile);
          switch (this.currentTab) {
            case AttendanceTypeEnum.monthly:
              return this.studentAttendanceService.currentMonthlyFilters$;
            case AttendanceTypeEnum.weekly:
              return this.studentAttendanceService.currentWeeklyFilters$;
          }
        }),
        tap((newFilters$) => this.filterRows(newFilters$)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    combineLatest([this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isTablet, isPanelOpen]) => (this.shortCard = isTablet && isPanelOpen)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnChanges({ college }: SimpleChanges): void {
    if (college?.currentValue) {
      const { absenceFormUrl, serviceEmail } = colleges.find((college) => college.code === this.college);
      this.absenceFormUrl = absenceFormUrl;
      this.serviceEmail = serviceEmail;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTabChanged(statistic: AttendanceStat) {
    this.currentTab = statistic.frequency;
    let newFilters$: Observable<Date[]>;
    if (statistic.frequency === AttendanceTypeEnum.weekly) {
      newFilters$ = this.studentAttendanceService.currentWeeklyFilters$;
    } else if (statistic.frequency === AttendanceTypeEnum.monthly) {
      newFilters$ = this.studentAttendanceService.currentMonthlyFilters$;
    }

    this.filterRows(newFilters$);
  }

  private filterRows(newFilters$: Observable<Date[]>) {
    this.filters$ = newFilters$;
    this.absences = [];
    this.unposted = [];

    if (this.currentTab === AttendanceTypeEnum.overall) {
      this.studentAttendanceService.attendanceResult$
        .pipe(
          take(1),
          filter((value) => !!value?.overall),
          map((value) => value.overall),
        )
        .subscribe(({ absences, unposted }) => {
          this.absences = absences;
          this.unposted = unposted;
        });
      return;
    }

    combineLatest([this.filters$, this.studentAttendanceService.attendanceResult$])
      .pipe(
        take(1),
        tap(([filters, attendanceResult]) => {
          const currentSummary: AttendanceSummary = attendanceResult?.[this.currentTab]?.find((attendanceSummary: AttendanceSummary) => {
            const startDate = createDateFromString(attendanceSummary.startDate);
            const endDate = createDateFromString(attendanceSummary.endDate);
            return startDate >= filters[0] && endDate <= filters[1];
          });

          this.absences = currentSummary?.absences || [];
          this.unposted = currentSummary?.unposted || [];
        }),
      )
      .subscribe();
  }

  refresh() {
    window.location.reload();
  }

  private updateCurrentTab(isMobile = false) {
    const frequency = this.route.snapshot.queryParamMap.get('frequency') as AttendanceTypeEnum;
    let preferredFrequency = isMobile ? AttendanceTypeEnum.weekly : AttendanceTypeEnum.monthly;

    if (Object.keys(AttendanceTypeEnum).includes(frequency)) {
      preferredFrequency = frequency;
    }

    this.currentTab = preferredFrequency;
    const tabIndex = this.attendanceStats.findIndex((item) => item.frequency === preferredFrequency);
    this.studentAttendanceService.setTabIndex(tabIndex);
  }
}
