<div class="pre-arrival-tasks-notification">
  <img alt="Edit icon" [ngSrc]="editImg" width="24" height="24" />
  <div class="pre-arrival-tasks-notification__content">
    <span class="pre-arrival-tasks-notification__title">Tasks to complete before arriving at college</span>
    <span class="pre-arrival-tasks-notification__subtitle">We will let the college know when you have completed all the tasks.</span>
    <a
      *ngIf="onlineRegistrationTask?.status === studentNotificationStatusEnum.new"
      class="pre-arrival-tasks-notification__link"
      href="javascript:void(0)"
      data-gtm-id="notification-panel-online-reg-form"
      (click)="openOnlineRegistration()"
    >
      Register online with your chosen college
    </a>
    <div *ngIf="onlineRegistrationTask?.status === studentNotificationStatusEnum.completed" class="pre-arrival-tasks-notification__text">
      <div class="pre-arrival-tasks-notification__text-icon"></div>
      <span class="pre-arrival-tasks-notification__text-message"> Register online with your chosen college </span>
    </div>
    <a
      *ngIf="addressConfirmationTask?.status === studentNotificationStatusEnum.new"
      class="pre-arrival-tasks-notification__link"
      href="javascript:void(0)"
      data-gtm-id="notification-panel-uk-address"
      (click)="isConfirmUkAddressOpen = true"
    >
      Confirm your UK address
    </a>
    <div *ngIf="addressConfirmationTask?.status === studentNotificationStatusEnum.completed" class="pre-arrival-tasks-notification__text">
      <div class="pre-arrival-tasks-notification__text-icon"></div>
      <span class="pre-arrival-tasks-notification__text-message"> Confirm your UK address </span>
    </div>
    <a
      *ngIf="collegeArrivalConfirmationTask?.status === studentNotificationStatusEnum.new"
      class="pre-arrival-tasks-notification__link"
      href="javascript:void(0)"
      data-gtm-id="notification-panel-prearrival-form"
      (click)="openCollegeArrivalConfirmation()"
    >
      Tell your college when you will arrive
    </a>
    <div *ngIf="collegeArrivalConfirmationTask?.status === studentNotificationStatusEnum.completed" class="pre-arrival-tasks-notification__text">
      <div class="pre-arrival-tasks-notification__text-icon"></div>
      <span class="pre-arrival-tasks-notification__text-message"> Tell your college when you will arrive </span>
    </div>
  </div>
</div>

<pw-confirm-uk-address *ngIf="isConfirmUkAddressOpen" (popupClosed)="isConfirmUkAddressOpen = false"></pw-confirm-uk-address>
