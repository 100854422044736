import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { StudentTimetable, StudentTimetableService } from '@pw/profile-data-access';
import { Observable } from 'rxjs';
import { SectionShellComponent, TimetableComponent } from '../../molecules';

@Component({
  standalone: true,
  imports: [CommonModule, SectionShellComponent, TimetableComponent],
  selector: 'pw-timetable-section',
  templateUrl: './timetable-section.component.html',
  styleUrl: './timetable-section.component.scss',
})
export class TimetableSectionComponent {
  studentTimetable$: Observable<StudentTimetable>;

  private readonly timetableService = inject(StudentTimetableService);

  constructor() {
    this.studentTimetable$ = this.timetableService.studentTimetable$;
  }
}
