import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardModule as PrimeNgCardModule } from 'primeng/card';
import { DividerModule as PrimeNgDividerModule } from 'primeng/divider';
import { DropdownModule as PrimeNgDropdownModule } from 'primeng/dropdown';
import { MessagesModule as PrimeNgMessagesModule } from 'primeng/messages';
import {
  AttendanceAbsencesTabsComponent,
  AttendanceCardComponent,
  AttendanceCarouselComponent,
  AttendanceDetailComponent,
  AttendanceFilterComponent,
  AttendanceOverviewComponent,
  AttendancePercentageComponent,
  AttendanceTableComponent,
  AttendanceTabsComponent,
  CardShellComponent,
  NotificationPanelComponent,
  PaymentsDueBoxComponent,
  PaymentsDueComponent,
  PaymentsDueNotificationComponent,
  PwBadgeComponent,
  PwButtonComponent,
} from './components';
import { ChangeEmailFormComponent } from './components/change-email-form/change-email-form.component';
import { ConfirmUkAddressComponent } from './components/confirm-uk-address/confirm-uk-address.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputComponent } from './components/input/input.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { MenuComponent } from './components/menu/menu.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';
import { PrivatePageMainComponent } from './components/private-page-main/private-page-main.component';
import { PublicPageComponent } from './components/public-page/public-page.component';
import { AddShowHideToPasswordInputsDirective } from './directives/add-show-hide-to-password-inputs/add-show-hide-to-password-inputs.directive';
import { GoogleTagManagerTrackEventsDirective } from './directives/google-tag-manager-track-events/google-tag-manager-track-events.directive';

@NgModule({
  declarations: [
    AddShowHideToPasswordInputsDirective,
    ChangeEmailFormComponent,
    CopyrightComponent,
    ErrorPageComponent,
    GoogleTagManagerTrackEventsDirective,
    InputComponent,
    InputPasswordComponent,
    LoadingOverlayComponent,
    NotificationComponent,
    PageHeaderComponent,
    PasswordValidatorComponent,
    PublicPageComponent,
  ],
  exports: [
    AddShowHideToPasswordInputsDirective,
    AttendanceAbsencesTabsComponent,
    AttendanceCardComponent,
    AttendanceCarouselComponent,
    AttendanceDetailComponent,
    AttendanceFilterComponent,
    AttendanceOverviewComponent,
    AttendancePercentageComponent,
    AttendanceTableComponent,
    AttendanceTabsComponent,
    CardShellComponent,
    ChangeEmailFormComponent,
    ConfirmUkAddressComponent,
    CopyrightComponent,
    ErrorPageComponent,
    GoogleTagManagerTrackEventsDirective,
    InputComponent,
    InputPasswordComponent,
    LoadingOverlayComponent,
    MenuComponent,
    NotificationComponent,
    NotificationPanelComponent,
    PageHeaderComponent,
    PasswordValidatorComponent,
    PaymentsDueBoxComponent,
    PaymentsDueComponent,
    PaymentsDueNotificationComponent,
    PrivatePageMainComponent,
    PublicPageComponent,
    PwBadgeComponent,
    PwButtonComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    // PrimeNG
    PrimeNgCardModule,
    PrimeNgDropdownModule,
    PrimeNgDividerModule,
    PrimeNgMessagesModule,
    // Pathways
    AttendanceAbsencesTabsComponent,
    AttendanceCardComponent,
    AttendanceCarouselComponent,
    AttendanceDetailComponent,
    AttendanceFilterComponent,
    AttendanceOverviewComponent,
    AttendancePercentageComponent,
    AttendanceTableComponent,
    AttendanceTabsComponent,
    CardShellComponent,
    ConfirmUkAddressComponent,
    MenuComponent,
    NotificationPanelComponent,
    PaymentsDueBoxComponent,
    PaymentsDueComponent,
    PaymentsDueNotificationComponent,
    PrivatePageMainComponent,
    PwBadgeComponent,
    PwButtonComponent,
  ],
})
export class PathwaysUiModule {}
