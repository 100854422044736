<pw-popup-shell [popupTitle]="selectedEvent?.title">
  <p>🕒 {{ selectedEvent?.start | date: 'HH:mm' }} - {{ selectedEvent?.end | date: 'HH:mm' }}</p>
  <ng-container *ngIf="selectedEvent?.extendedProps">
    <div>
      <h4>Additional details:</h4>
      <ul>
        <li *ngIf="selectedEventProps?.venue">Room: <span [innerHTML]="selectedEventProps?.venue"></span></li>
        <li *ngIf="selectedEventProps?.meetingUrl; let url">
          <a [href]="url">Join the meeting now</a>
        </li>
        <li>Tutor: <span [innerHTML]="selectedEventProps?.presenter ?? '<i>No Tutor</i>'"></span></li>
      </ul>
    </div>
  </ng-container>
  <pw-button label="Close" [size]="buttonSize.Medium" [secondary]="true" (buttonClicked)="popupClosed.emit()" i18n-label></pw-button>
</pw-popup-shell>
