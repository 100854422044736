import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Severity, Size } from '@pw/shared/types';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'pw-button',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, ButtonModule],
  templateUrl: './pw-button.component.html',
  styleUrls: ['./pw-button.component.scss'],
})
export class PwButtonComponent implements OnChanges, AfterViewInit {
  @ViewChild('pButton', { read: ElementRef }) pButtonRef: ElementRef;
  @Input() buttonId = '';
  @Input() label = '';
  @Input() severity: Severity = Severity.Primary;
  @Input() size: Size = Size.Small;
  @Input() secondary = false;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() href = '';
  @Input() target = '_blank';
  @Input() gtmId = '';
  @Input() icon = '';
  @Input() piIcon = '';
  @Input() extraClass = '';
  @Output() buttonClicked = new EventEmitter<MouseEvent>();

  styleClass = '';
  iconPath = '';

  ngOnChanges({ icon }: SimpleChanges): void {
    // hack to bypass limitation of ngSrc (https://github.com/angular/angular/issues/47813)
    setTimeout(() => (this.iconPath = icon?.currentValue && !this.piIcon ? `/assets/icons/${this.icon}.svg` : ''));

    const sizeClass = `p-button-${this.size}`;
    const severityClass = `p-button-${this.severity}`;
    this.styleClass = `${sizeClass}${this.disabled ? ` ${severityClass}` : ` ${severityClass}${this.secondary ? ' p-button-outlined' : ''}`}${this.extraClass ? ` ${this.extraClass}` : ''}`;
  }

  ngAfterViewInit(): void {
    const button = this.pButtonRef.nativeElement.querySelector('button');
    if (button) {
      button.setAttribute('id', this.buttonId);
    }
  }

  onClick(event: MouseEvent) {
    if (this.href) {
      window.open(this.href, this.target);
    } else {
      this.buttonClicked.emit(event);
    }
  }
}
