import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { Observable } from 'rxjs';
import { PrivatePageMainComponent } from '../../private-page-main/private-page-main.component';

@Component({
  standalone: true,
  imports: [CommonModule, BreadcrumbModule, DialogModule, PrivatePageMainComponent],
  selector: 'pw-popup-shell',
  templateUrl: './popup-shell.component.html',
  styleUrls: ['./popup-shell.component.scss'],
})
export class PopupShellComponent {
  @Input() isVisible = true;
  @Input() popupTitle = '';
  @Input() breadcrumbHome: MenuItem = {
    icon: 'pi pi-arrow-left',
    label: $localize`Home`,
    command: () => this.homeLinkClicked.emit(),
  };
  @Output() homeLinkClicked = new EventEmitter<void>();

  isMobile$: Observable<boolean>;

  private readonly breakpointObserverService = inject(BreakpointObserverService);

  constructor() {
    this.isMobile$ = this.breakpointObserverService.isMobile$;
  }
}
