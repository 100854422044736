import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { TimetableSession } from '@pw/profile-data-access';
import { Size } from '@pw/shared/types';
import { PwButtonComponent } from '../../atoms';
import { PopupShellComponent } from '../popup-shell/popup-shell.component';

@Component({
  standalone: true,
  imports: [CommonModule, PopupShellComponent, PwButtonComponent],
  selector: 'pw-timetable-popup',
  templateUrl: './timetable-popup.component.html',
  styleUrl: './timetable-popup.component.scss',
})
export class TimetablePopupComponent implements OnInit {
  @Input() selectedEvent!: EventImpl;
  @Output() popupClosed = new EventEmitter<void>();

  selectedEventProps: Partial<TimetableSession> = {};

  protected readonly buttonSize = Size;

  ngOnInit(): void {
    this.selectedEventProps = {
      venue: this.selectedEvent?.extendedProps?.venue ?? '',
      presenter: this.selectedEvent?.extendedProps?.presenter ?? '',
      meetingUrl: this.selectedEvent?.extendedProps?.meetingUrl ?? '',
    };
  }
}
