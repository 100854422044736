import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@pw/account/auth';
import { StudentFeatureEnum } from '@pw/profile-data-access';
import { CivicCookiesWithOptionalsService } from '@pw/shared/cookies';
import { environment, PATHWAYS_ENVIRONMENT, PathwaysAccountRoutes, PathwaysEnvironment } from '@pw/shared/environment';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames } from '@pw/shared/types';
import { AccountMenuService } from '@pw/shared/ui';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'pw-account-menu',
  standalone: false,
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  currentUser: User = new User({});
  userMenuItems: MenuItem[];

  private readonly environment = inject<PathwaysEnvironment>(PATHWAYS_ENVIRONMENT);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);
  private readonly cookiesService = inject(CivicCookiesWithOptionalsService);
  private readonly accountMenuService = inject(AccountMenuService);

  public ngOnInit(): void {
    this.currentUser = this.activatedRoute.snapshot?.data?.currentUser;

    this.userMenuItems = [
      {
        id: 'manage-account-link',
        label: 'Manage account',
        gtmId: GtmEventNames.MENU_MANAGE_ACCOUNT_LINK,
        command: () => this.router.navigate(['/', PathwaysAccountRoutes.MANAGE_ACCOUNT]),
      },
      { separator: true },
      {
        id: 'cookie-settings-link',
        label: 'Cookie settings',
        gtmId: GtmEventNames.MENU_COOKIE_SETTINGS_LINK,
        command: () => this.cookiesService.open(),
      },
      {
        id: 'terms-of-use-link',
        label: 'Terms of use',
        icon: 'external-link',
        gtmId: GtmEventNames.MENU_TERMS_OF_USE_LINK,
        command: () => {
          const url = `${this.environment.PORTAL_PATH}/${PathwaysAccountRoutes.TERMS_OF_USE}`;
          window.open(url, '_blank');
        },
      },
      {
        id: 'privacy-policy-link',
        label: 'Privacy and cookie policy',
        icon: 'external-link',
        gtmId: GtmEventNames.MENU_PRIVACY_POLICY_LINK,
        command: () => window.open(this.environment.PRIVACY_URL, '_blank'),
      },
      { separator: true },
      {
        id: 'sign-out-link',
        label: 'Sign out',
        gtmId: GtmEventNames.MENU_SIGN_OUT_LINK,
        command: () => this.accountMenuService.showSignOut(),
      },
    ];

    const attendanceFeature = this.currentUser.features.find((feature) => feature.id === StudentFeatureEnum.attendance);
    const showAttendanceMenuItem = environment.FEATURE_FLAGS.ATTENDANCE_OVERVIEW_ENABLED && attendanceFeature?.enabled;

    if (showAttendanceMenuItem) {
      this.userMenuItems.splice(1, 0, {
        id: 'attendance-overview-link',
        label: 'Attendance',
        gtmId: GtmEventNames.HOME_ATTENDANCE_MENU_OPTION,
        command: () => this.router.navigate(['/', PathwaysAccountRoutes.ATTENDANCE_OVERVIEW]),
      });
    }
  }
}
