<div pwGoogleTagManagerTrackEvents>
  <pw-page-header class="base-page__page-header" [mainPage]="mainPage">
    <pw-account-menu header-right></pw-account-menu>
  </pw-page-header>
  <main class="base-page__main" [class.base-page__main--panel-open]="isPanelOpen$ | async">
    <section *ngIf="showSection" class="base-page__section" [class.base-page__section--manage-account]="isManageAccount">
      <router-outlet></router-outlet>
    </section>
    <aside class="base-page__sidebar" *ngIf="isPanelOpen$ | async">
      <pw-notification-panel class="base-page__notification-panel" [studentNotifications]="studentNotifications$ | async"></pw-notification-panel>
    </aside>

    <pw-sign-out-popup *ngIf="isSignOutVisible$ | async"></pw-sign-out-popup>
  </main>
</div>
