import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { StudentAttendance, StudentAttendanceService } from '@pw/profile-data-access';
import { from } from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';
import { PathwaysAuthService } from '../services/auth.service';

export const studentAttendanceResolver: ResolveFn<StudentAttendance> = () => {
  const authService = inject(PathwaysAuthService);
  const studentAttendanceService = inject(StudentAttendanceService);

  const masterProfileId = authService.getActiveAccountMasterProfileId();
  return from(authService.initialize()).pipe(
    concatMap(() =>
      authService.ssoSilent().pipe(
        take(1),
        map(() => authService.getBearerToken()),
        switchMap((token) => studentAttendanceService.getAttendances(token, masterProfileId)),
      ),
    ),
  );
};
