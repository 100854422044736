import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AttendanceTypeEnum, StudentAttendanceService } from '@pw/profile-data-access';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames } from '@pw/shared/types';

@Component({
  selector: 'pw-attendance-filter',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, OverlayPanelModule, MenuModule],
  templateUrl: './attendance-filter.component.html',
  styleUrls: ['./attendance-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AttendanceFilterComponent implements OnChanges {
  @ViewChild('op') overlayPanel!: OverlayPanel;
  @Input() filterId = '';
  @Input() frequency: string = AttendanceTypeEnum.weekly;
  @Input() showFilter = true;
  @Input() menuItems: MenuItem[] = [];

  caretDownImg = 'assets/icons/caret-down.svg';

  private readonly attendanceService = inject(StudentAttendanceService);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);

  ngOnChanges({ menuItems }: SimpleChanges): void {
    if (menuItems?.currentValue?.length) {
      this.menuItems.forEach(
        (item) =>
          (item.command = () => {
            this.overlayPanel.hide();
            this.attendanceService.setCurrentFilters(this.frequency, item.label);
            let event = GtmEventNames.DETAIL_ATTENDANCE_MONTHLY_FILTER;
            if (this.frequency === AttendanceTypeEnum.weekly) {
              event = GtmEventNames.DETAIL_ATTENDANCE_WEEKLY_FILTER;
            }
            this.gtmService.pushTag({ event });
          }),
      );
    }
  }
}
