import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { emailValidator } from '@klg/core/validators';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { Size, GtmEventNames } from '@pw/shared/types';
import { ToastMessageOptions } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pw-change-email-form',
  standalone: false,
  templateUrl: './change-email-form.component.html',
  styleUrls: ['./change-email-form.component.scss'],
})
export class ChangeEmailFormComponent implements OnInit, OnDestroy {
  @Input() previousEmail = '';
  @Input() requestConfirmation = false;
  @Input() confirmButtonText = $localize`Confirm`;
  @Input() formMessage?: ToastMessageOptions;
  @Input() sending = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() cancel = new EventEmitter<void>();
  @Output() updateEmailAddress = new EventEmitter<string>();
  @Output() emailMatchPrevious = new EventEmitter<boolean>();
  @Output() notificationMessageClosed = new EventEmitter<boolean>();

  public email = '';
  public emailConfirmation = '';
  public formIsValid = false;
  public emailErrorText = '';
  public emailConfirmationErrorText = '';
  public maxlengthAllowedforQrcode = 50;
  public isMobile = false;
  buttonSize = Size;
  gmEventNames= GtmEventNames;

  private subscriptions = new Subscription();

  constructor(private readonly breakpointObserverService: BreakpointObserverService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.breakpointObserverService.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      }),
    );
  }

  ngOnDestroy(): void {
    this.emailMatchPrevious.emit(null);
    this.subscriptions.unsubscribe();
  }

  public emitNotificationMessageClosed() {
    this.formMessage = null;
    this.notificationMessageClosed.emit();
  }

  public validateEmail(): boolean {
    this.emailErrorText = this.getEmailValidationError(this.email);
    return !this.emailErrorText && this.requestConfirmation ? this.validateEmailConfirm() : !this.emailErrorText;
  }

  public validateEmailConfirm(): boolean {
    this.emailConfirmationErrorText = this.requestConfirmation ? this.getEmailValidationError(this.emailConfirmation) : '';
    if (!this.emailConfirmationErrorText && this.emailConfirmation && this.emailConfirmation !== this.email) {
      this.emailConfirmationErrorText = $localize`Your email addresses don’t match. Please try again.`;
    }
    return !this.emailConfirmationErrorText;
  }

  public submit() {
    if (this.validateEmail() && this.validateEmailConfirm() && this.validateEmailMatchPrevious()) {
      this.updateEmailAddress.emit(this.email);
    }
  }

  private validateEmailMatchPrevious(): boolean {
    const emailMatchPrevious = this.email === this.previousEmail;
    this.formMessage = emailMatchPrevious
      ? {
          severity: 'error',
          detail: $localize`Please enter a different email address.`,
        }
      : undefined;

    this.emailMatchPrevious.emit(emailMatchPrevious);
    return !emailMatchPrevious;
  }

  private getEmailValidationError(email: string): string {
    if (email === '') {
      return $localize`Please enter your email address.`;
    } else if (!emailValidator(email)) {
      return $localize`Your email address is incorrect. Please try again.`;
    } else if (email.length > this.maxlengthAllowedforQrcode) {
      return $localize`Email length exceeded. Please use a shorter email.`;
    } else {
      return '';
    }
  }
}
