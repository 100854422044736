<ng-container *ngIf="isMobile$ | async; else dialog">
  <p-breadcrumb [home]="breadcrumbHome"></p-breadcrumb>
  <pw-private-page-main pageTitle="{{ popupTitle }}" i18n-pageTitle>
    <ng-container *ngTemplateOutlet="content" />
  </pw-private-page-main>
</ng-container>
<ng-template #dialog>
  <p-dialog [visible]="isVisible" [modal]="true" [draggable]="false" [resizable]="false" [closable]="false">
    <ng-template pTemplate="header">
      <h6 i18n>{{ popupTitle }}</h6>
    </ng-template>
    <ng-template pTemplate="content">
      <ng-container *ngTemplateOutlet="content" />
    </ng-template>
  </p-dialog>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
