import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, DatesSetArg, DayHeaderContentArg, EventClickArg, EventContentArg, EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { getMinAndMaxDatesFromTimetable, TimetableSession, transformTimetableSessionToEventInput } from '@pw/profile-data-access';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames, TimetableView } from '@pw/shared/types';
import { isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import { distinctUntilChanged, map, Observable, of, shareReplay, take, tap } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { CardShellComponent } from '../card-shell/card-shell.component';
import { TimetablePopupComponent } from '../timetable-popup/timetable-popup.component';

@Component({
  standalone: true,
  imports: [CommonModule, FullCalendarModule, CardShellComponent, TimetablePopupComponent],
  selector: 'pw-timetable',
  templateUrl: './timetable.component.html',
  styleUrl: './timetable.component.scss',
})
export class TimetableComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendar: FullCalendarComponent;
  @Input() timetable: TimetableSession[] = [];

  lastView = TimetableView.WEEKLY_VIEW;
  lastStartDate: Date | null = null;
  lastActiveDate: Date | null = null;
  eventsPromise: Promise<EventInput[]>;
  isMobile = false;
  isTablet = false;
  isPanelOpen$: Observable<boolean>;
  resizeCalendar$: Observable<boolean> = of(false);
  selectedEvent!: EventImpl;

  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, timeGridPlugin, listPlugin],
    initialView: this.lastView,
    locale: 'en-GB',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridWeek,timeGridDay,listWeek',
    },
    height: 'auto',
    titleFormat: { year: 'numeric', month: 'long' },
    dayHeaders: true,
    dayHeaderFormat: { weekday: 'long', day: 'numeric', omitCommas: true },
    weekends: false,
    firstDay: 1,
    allDaySlot: false,
    displayEventTime: false,
    displayEventEnd: false,
    slotMinTime: '08:00:00',
    slotMaxTime: '20:00:00',
    eventColor: '#005DE8',
    eventContent: (eventContent) => this.updateEventContent(eventContent),
    eventClick: (eventClick) => this.handleEventClick(eventClick),
    datesSet: (datesSet) => this.handleDatesSet(datesSet),
  };

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);
  private readonly notificationPanelService = inject(NotificationPanelService);

  constructor() {
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$.pipe(shareReplay(1));
  }

  ngOnInit(): void {
    const events = this.timetable?.map((event) => transformTimetableSessionToEventInput(event)) || [];
    this.eventsPromise = Promise.resolve(events);

    const { minDate, maxDate } = getMinAndMaxDatesFromTimetable(this.timetable);

    this.calendarOptions = {
      ...this.calendarOptions,
      validRange: { start: minDate, end: maxDate },
    };

    this.breakpointObserverService.isMobile$.pipe(take(1)).subscribe((isMobile) => (this.isMobile = isMobile));
    this.breakpointObserverService.isTablet$.pipe(take(1)).subscribe((isTablet) => (this.isTablet = isTablet));
  }

  ngAfterViewInit(): void {
    const calendarApi = this.calendar.getApi();

    this.resizeCalendar$ = this.isPanelOpen$.pipe(
      map((isPanelOpen) => (isPanelOpen && this.isTablet) || this.isMobile),
      tap(() => setTimeout(() => calendarApi.updateSize())),
      distinctUntilChanged(),
      tap((shouldResize) => {
        this.calendarOptions = {
          ...this.calendarOptions,
          views: {
            timeGridWeek: {
              dayHeaderContent: (dayHeaderContent: DayHeaderContentArg) => this.setWeekHeaderContent(dayHeaderContent, shouldResize),
            },
          },
        };
      }),
    );

    const initView = this.isMobile ? TimetableView.DAILY_VIEW : TimetableView.WEEKLY_VIEW;
    calendarApi.changeView(initView);
    setTimeout(() => calendarApi.updateSize());
  }

  private updateEventContent(eventContent: EventContentArg) {
    const location = eventContent.event.extendedProps.venue || '';
    return { html: `<b>${eventContent.event.title}</b><div id="session-location">${location}</div>` };
  }

  private handleEventClick(eventClick: EventClickArg): void {
    this.selectedEvent = eventClick.event;
    this.gtmService.pushTag({ event: GtmEventNames.VIEW_SPECIFIC_TIMETABLE_SESSION });
  }

  private handleDatesSet(datesSet: DatesSetArg) {
    if (!this.calendar) {
      return;
    }

    const calendarApi = this.calendar.getApi();
    if (!calendarApi || !calendarApi.getDate) {
      return;
    }

    const newActiveDate = startOfDay(new Date(calendarApi.getDate())); // Currently highlighted day
    const newStartDate = startOfDay(new Date(datesSet.start)); // Start of visible range
    const today = startOfDay(new Date());

    // Initialize tracking variables on first load
    if (!this.lastStartDate || !this.lastActiveDate) {
      this.lastStartDate = newStartDate;
      this.lastActiveDate = newActiveDate;
      return;
    }

    // Prevent duplicate events that can happen when we programmatically update the view when the isMobile$ is resolved
    if (isEqual(this.lastActiveDate, newActiveDate) && this.lastView === datesSet.view.type) {
      return;
    }

    let gtmEventName = '';
    if (!isEqual(this.lastActiveDate, newActiveDate) && isEqual(newActiveDate, today)) {
      gtmEventName = GtmEventNames.VIEW_TODAY_TIMETABLE_SESSIONS;
    } else if (datesSet.view.type !== this.lastView) {
      switch (datesSet.view.type) {
        case TimetableView.WEEKLY_VIEW:
          gtmEventName = GtmEventNames.VIEW_WEEKLY_TIMETABLE_SESSIONS;
          break;
        case TimetableView.LISTING_VIEW:
          gtmEventName = GtmEventNames.LIST_TIMETABLE_SESSIONS;
          break;
        case TimetableView.DAILY_VIEW:
          gtmEventName = GtmEventNames.VIEW_DAILY_TIMETABLE_SESSIONS;
          break;
        default:
      }
    } else {
      if (this.lastStartDate) {
        if (isBefore(newStartDate, this.lastStartDate)) {
          gtmEventName = GtmEventNames.VIEW_PREVIOUS_TIMETABLE_SESSIONS;
        } else if (isAfter(newStartDate, this.lastStartDate)) {
          gtmEventName = GtmEventNames.VIEW_NEXT_TIMETABLE_SESSIONS;
        }
      }
    }

    if (gtmEventName) {
      this.gtmService.pushTag({ event: gtmEventName });
    }
    this.lastView = datesSet.view.type as TimetableView;
    this.lastStartDate = newStartDate;
    this.lastActiveDate = newActiveDate;
  }

  private setWeekHeaderContent(dayHeaderContent: DayHeaderContentArg, shouldResize: boolean) {
    if (dayHeaderContent.view.type === TimetableView.WEEKLY_VIEW && shouldResize) {
      const parts = dayHeaderContent.text.split(' ');
      return { html: `${parts[0].slice(0, 3)}<br>${parts[1]}` };
    }

    return { html: dayHeaderContent.text };
  }
}
