<div class="payments-due-notification__box">
  <div class="payments-due-notification__college-logo">
    <img *ngIf="collegeLogo" alt="College logo" [src]="collegeLogo" />
  </div>
  <div class="payments-due-notification__title">{{ payment?.title }}</div>
  <div class="payments-due-notification__payments-due">
    <pw-payments-due *ngIf="payment.itemsOverDue" [numberOfPayments]="payment.itemsOverDue" [type]="paymentType.OverDue"></pw-payments-due>
    <div class="payments-due-notification__payments-due-separator" *ngIf="payment.itemsOverDue && payment.itemsDueSoon"></div>
    <pw-payments-due *ngIf="payment.itemsDueSoon" [numberOfPayments]="payment.itemsDueSoon" [type]="paymentType.DueSoon"></pw-payments-due>
  </div>
</div>
<pw-button class="payments-due-notification__view-button" label="View" [severity]="severity.Primary" [secondary]="!isFirst" [href]="oppUrl"></pw-button>
