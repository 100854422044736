import { Injectable } from '@angular/core';
import { VerificationsApiService } from '@pw/api/verification';
import { ReadProfileResponseDtoApiDto } from '@pw/api/profile';
import { BehaviorSubject, Observable } from 'rxjs';
import { InlineResponse202, VerificationTypes } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TokenValidationService {
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  constructor(private verificationApi: VerificationsApiService) {}

  checkToken(verificationId: string, verificationToken: string): Observable<ReadProfileResponseDtoApiDto> {
    return this.verificationApi.verifyRegistrationEmail(verificationId, { verificationToken });
  }

  resendVerificationEmail(profileEmail: string, masterProfileId: string, signUp = false) {
    return this.verificationApi.createVerification({
      profileEmail,
      verificationType: signUp ? VerificationTypes.REGISTRATION_EMAIL : VerificationTypes.UPDATED_REGISTRATION_EMAIL,
      masterProfileId,
    });
  }

  resetQrCodeVerificationEmail(masterProfileId: string): Observable<InlineResponse202> {
    return this.verificationApi.createVerification({
      verificationType: VerificationTypes.RESET_QR_CODE_EMAIL,
      masterProfileId,
    });
  }

  resetAccountCodeVerificationEmail(masterProfileId: string): Observable<InlineResponse202> {
    return this.verificationApi.createVerification({
      verificationType: VerificationTypes.RESET_ACCOUNT_EMAIL,
      masterProfileId,
    });
  }
}
