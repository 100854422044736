import { EventInput } from '@fullcalendar/core';
import { max, min } from 'date-fns';
import { TimetableSession } from '../models';

export const transformTimetableSessionToEventInput = (timetableSession: TimetableSession): EventInput => {
  const { id, start, end, description, ...rest } = timetableSession;

  return {
    id,
    start: new Date(start),
    end: new Date(end),
    title: description,
    extendedProps: {
      ...rest,
    },
  };
};

export const getMinAndMaxDatesFromTimetable = (timetable: TimetableSession[]): { minDate: Date; maxDate: Date } => {
  const dates = timetable?.map((timetable) => new Date(timetable.start)) || [];

  return { minDate: min(dates), maxDate: max(dates) };
};
