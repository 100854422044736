<pw-section-shell
  sectionHeader="Your attendance"
  [homeAnchorGtmEventName]="gtmEventNames.HOME_ATTENDANCE_LINK"
  [sectionRoute]="pathwaysRoutes.ATTENDANCE_OVERVIEW"
>
  <ng-container *ngIf="isLoading">
    <p-skeleton width="40rem" height="1rem" styleClass="mb-2" />
    <p-skeleton width="40rem" height="2rem" styleClass="mb-2" />
    <p-skeleton width="40rem" height="3rem" styleClass="mb-2" />
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <span *ngIf="isAttendanceDataAvailable && (attendancesError$ | async) === undefined" class="attendance-overview__updated" i18n
      >Last updated on {{ lastModifiedAt | date: 'd MMMM YYYY' }}</span
    >
    <pw-button
      *ngIf="isAttendanceDataAvailable"
      buttonId="attendance-overview-submit-absence-form-button"
      class="attendance-overview__button"
      label="Submit Absence Form"
      [secondary]="true"
      icon="arrow-up-right"
      [gtmId]="gtmEventNames.HOME_ATTENDANCE_SUBMIT_ABSENCE_FORM_BUTTON"
      [href]="absenceFormUrl"
    ></pw-button>
    <div *ngIf="(attendancesError$ | async) !== undefined; else attendanceOverview" class="attendance-overview__no-data" i18n>
      We can't load your attendance. Try <a (click)="refresh()">refreshing</a> the page. If your attendance still isn't loading, please
      <a [href]="'mailto:' + supportEmail">contact us.</a>
    </div>
    <ng-template #attendanceOverview>
      <pw-attendance-card
        *ngIf="isAttendanceDataAvailable"
        class="attendance-overview__statistics"
        [class.attendance-overview__statistics--short-card]="shortCard"
        [attendanceStats]="(attendanceStats$ | async)?.stats"
      ></pw-attendance-card>
      <div *ngIf="!isAttendanceDataAvailable" class="attendance-overview__no-data" i18n>No data available yet</div>
    </ng-template>
  </ng-container>
</pw-section-shell>
