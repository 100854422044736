import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PaymentType } from '@pw/shared/types';

@Component({
  selector: 'pw-payments-due',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './payments-due.component.html',
  styleUrls: ['./payments-due.component.scss'],
})
export class PaymentsDueComponent {
  @Input() numberOfPayments = 0;
  @Input() type: PaymentType = PaymentType.OverDue;

  get paymentClass(): string {
    return this.type === PaymentType.OverDue ? 'payments-due__overdue' : 'payments-due__duesoon';
  }

  get paymentText(): string {
    return this.type === PaymentType.OverDue ? 'OVERDUE' : 'DUE SOON';
  }
}
