import { inject, Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PathwaysAuthService } from '@pw/account/auth';
import { environment } from '@pw/shared/environment';
import { StudentFeatureEnum } from '@pw/profile-data-access';

@Injectable({
  providedIn: 'root',
})
export class PathwaysGoogleTagManagerService extends GoogleTagManagerService {
  private readonly authService = inject(PathwaysAuthService);

  async pushTag(item: object): Promise<void> {
    try {
      const currentUser = this.authService.currentUser;
      let tagData = item;
      if (currentUser?.masterProfileId) {
        const attendanceFeature = currentUser.features.find((feature) => feature.id === StudentFeatureEnum.attendance);
        tagData = {
          ...item,
          user_id: currentUser.masterProfileId,
          preArrivalRequired: !!currentUser.preArrival?.required,
          attendanceEnabled: environment.FEATURE_FLAGS.ATTENDANCE_OVERVIEW_ENABLED && !!attendanceFeature?.enabled,
        };
      }

      return await super.pushTag(tagData);
    } catch (error) {
      console.warn('Error occurred while pushing GA tag. Likely could not load GA script', error);
    }
  }

  async addGtmToDom(): Promise<boolean> {
    try {
      return await super.addGtmToDom();
    } catch (error) {
      console.warn('Error occurred while adding GTM script to DOM', error);
      return false;
    }
  }
}
