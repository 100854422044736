<pw-section-shell sectionHeader="Attendance" [headerIsAnchor]="false">
  <span *ngIf="isDataAvailable" class="attendance-detail__updated" i18n>Last updated on {{ lastModifiedAt | date: 'd MMMM YYYY' }}</span>
  <div *ngIf="!isDataAvailable" class="attendance-overview__no-data" i18n>No data available yet</div>
  <div *ngIf="attendancesError$ | async" class="attendance-overview__no-data" i18n>
    We can't load your attendance. Try <a (click)="refresh()">refreshing</a> the page. If your attendance still isn't loading, please<a
      [href]="'mailto:' + supportEmail"
      >contact us.</a
    >
  </div>
  <div class="attendance-detail__info">
    <img alt="Info icon" [ngSrc]="infoImg" width="16" height="16" />
    <span i18n>Changes may not show here immediately. Please come back later if you are waiting for an update</span>
  </div>
  <pw-button
    *ngIf="isDataAvailable"
    buttonId="attendance-detail-submit-absence-form-button"
    class="attendance-detail__button"
    label="Submit Absence Form"
    [secondary]="true"
    icon="arrow-up-right"
    [gtmId]="gtmEventNames.DETAIL_ATTENDANCE_SUBMIT_ABSENCE_FORM_BUTTON"
    [href]="absenceFormUrl"
    i18n-label
  ></pw-button>
  <ng-container *ngIf="isDataAvailable">
    <pw-attendance-tabs
      [class.attendance-detail__tabs--short-card]="shortCard"
      [attendanceStats]="(attendanceStats$ | async)?.stats"
      (tabChanged)="onTabChanged($event)"
    >
      <div class="attendance-detail__table" [class.attendance-detail__table--panel-open]="isPanelOpen$ | async">
        <pw-attendance-absences-tabs [absences]="absences" [unposted]="unposted"></pw-attendance-absences-tabs>
      </div>
    </pw-attendance-tabs>
  </ng-container>
  <span class="attendance-detail__help" i18n
    >If you have any questions, please contact College staff at
    <a class="attendance-detail__help-link" href="mailto:{{ serviceEmail }}" [attr.data-gtm-id]="gtmEventNames.DETAIL_ATTENDANCE_COLLEGE_EMAIL_LINK">{{
      serviceEmail
    }}</a></span
  >
  <pw-button
    buttonId="attendance-detail-policy-button"
    class="attendance-detail__button"
    label="Attendance Policy"
    [secondary]="true"
    icon="document"
    href="https://pathways.kaplaninternational.com/pluginfile.php/432111/mod_resource/content/8/KI Pathways Engagement and Attendance Policy.pdf"
    [gtmId]="gtmEventNames.DETAIL_ATTENDANCE_POLICY_BUTTON"
    i18n-label
  ></pw-button>
</pw-section-shell>
