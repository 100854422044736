import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, DatesSetArg, EventClickArg, EventContentArg, EventInput, ViewContentArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { getMinAndMaxDatesFromTimetable, StudentTimetableService, TimetableSession, transformTimetableSessionToEventInput } from '@pw/profile-data-access';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames } from '@pw/shared/types';
import { isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import { Subject, takeUntil, tap } from 'rxjs';
import { CardShellComponent } from '../card-shell/card-shell.component';
import { TimetableViewTypes } from './timetable-view-types.enum';

@Component({
  standalone: true,
  imports: [CommonModule, FullCalendarModule, CardShellComponent],
  selector: 'pw-timetable',
  templateUrl: './timetable.component.html',
  styleUrl: './timetable.component.scss',
})
export class TimetableComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendar: FullCalendarComponent;
  @Input() timetable: TimetableSession[] = [];

  lastView = TimetableViewTypes.WEEKLY_VIEW;
  lastStartDate: Date | null = null;
  lastActiveDate: Date | null = null;
  eventsPromise: Promise<EventInput[]>;

  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, timeGridPlugin, listPlugin],
    initialView: this.lastView,
    locale: 'en-GB',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridWeek,timeGridDay,listWeek',
    },
    height: 'auto',
    titleFormat: { year: 'numeric', month: 'long' },
    dayHeaders: false,
    dayHeaderFormat: { weekday: 'long', day: 'numeric', omitCommas: true },
    weekends: false,
    firstDay: 1,
    displayEventTime: false,
    displayEventEnd: false,
    slotMinTime: '08:00:00',
    slotMaxTime: '20:00:00',
    eventColor: '#005DE8',
    eventContent: (eventContent) => this.showLocation(eventContent),
    viewDidMount: (viewInfo) => this.hideHeaderOnMonthView(viewInfo),
    eventClick: (eventClick) => this.handleEventClick(eventClick),
    datesSet: (datesSet) => this.handleDatesSet(datesSet),
  };

  private readonly destroy$ = new Subject<void>();
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly timetableService = inject(StudentTimetableService);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);

  ngOnInit(): void {
    const events = this.timetable?.map((event) => transformTimetableSessionToEventInput(event)) || [];
    this.eventsPromise = Promise.resolve(events);

    const { minDate, maxDate } = getMinAndMaxDatesFromTimetable(this.timetable);
    this.calendarOptions = { ...this.calendarOptions, validRange: { start: minDate, end: maxDate } };
  }

  ngAfterViewInit(): void {
    this.breakpointObserverService.isMobile$
      .pipe(
        tap((isMobile) => this.calendar.getApi()?.changeView(isMobile ? TimetableViewTypes.DAILY_VIEW : TimetableViewTypes.WEEKLY_VIEW)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private showLocation(eventContent: EventContentArg) {
    const location = eventContent.event.extendedProps.venue || '';
    return { html: `<b>${eventContent.event.title}</b><div>${location}</div>` };
  }

  private hideHeaderOnMonthView(viewInfo: ViewContentArg) {
    const calendarApi = viewInfo.view.calendar;
    calendarApi.setOption('dayHeaders', calendarApi.view.type !== 'dayGridMonth');
  }

  private handleEventClick(eventClick: EventClickArg): void {
    this.timetableService.setSelectedEvent(eventClick.event);
    this.timetableService.setEventOpen(true);
    this.gtmService.pushTag({ event: GtmEventNames.VIEW_SPECIFIC_TIMETABLE_SESSION });
  }

  private handleDatesSet(datesSet: DatesSetArg) {
    const calendarApi = this.calendar.getApi();
    const newActiveDate = startOfDay(new Date(calendarApi.getDate())); // Currently highlighted day
    const newStartDate = startOfDay(new Date(datesSet.start)); // Start of visible range
    const today = startOfDay(new Date());

    // Initialize tracking variables on first load
    if (!this.lastStartDate || !this.lastActiveDate) {
      this.lastStartDate = newStartDate;
      this.lastActiveDate = newActiveDate;
      return;
    }

    // Prevent duplicate events that can happen when we programmatically update the view when the isMobile$ is resolved
    if (isEqual(this.lastActiveDate, newActiveDate) && this.lastView === datesSet.view.type) {
      return;
    }

    let gtmEventName = '';
    if (!isEqual(this.lastActiveDate, newActiveDate) && isEqual(newActiveDate, today)) {
      gtmEventName = GtmEventNames.VIEW_TODAY_TIMETABLE_SESSIONS;
    } else if (datesSet.view.type !== this.lastView) {
      switch (datesSet.view.type) {
        case TimetableViewTypes.WEEKLY_VIEW:
          gtmEventName = GtmEventNames.VIEW_WEEKLY_TIMETABLE_SESSIONS;
          break;
        case TimetableViewTypes.LISTING_VIEW:
          gtmEventName = GtmEventNames.LIST_TIMETABLE_SESSIONS;
          break;
        case TimetableViewTypes.DAILY_VIEW:
          gtmEventName = GtmEventNames.VIEW_DAILY_TIMETABLE_SESSIONS;
          break;
        default:
      }
    } else {
      if (this.lastStartDate) {
        if (isBefore(newStartDate, this.lastStartDate)) {
          gtmEventName = GtmEventNames.VIEW_PREVIOUS_TIMETABLE_SESSIONS;
        } else if (isAfter(newStartDate, this.lastStartDate)) {
          gtmEventName = GtmEventNames.VIEW_NEXT_TIMETABLE_SESSIONS;
        }
      }
    }

    if (gtmEventName) {
      this.gtmService.pushTag({ event: gtmEventName });
    }
    this.lastView = datesSet.view.type as TimetableViewTypes;
    this.lastStartDate = newStartDate;
    this.lastActiveDate = newActiveDate;
  }
}
