import { inject, Injectable } from '@angular/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { SATimetableApiService } from '@pw/api/profile';
import { PopupService } from '@pw/shared/services';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { StudentTimetable } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StudentTimetableService {
  studentTimetable$: Observable<StudentTimetable>;
  selectedEvent$: Observable<EventImpl>;

  private studentTimetable = new BehaviorSubject<StudentTimetable>(null);
  private selectedEvent = new BehaviorSubject<EventImpl>(null);

  private readonly timetableApiService = inject(SATimetableApiService);
  private readonly popupService = inject(PopupService);

  constructor() {
    this.studentTimetable$ = this.studentTimetable.asObservable();
    this.selectedEvent$ = this.selectedEvent.asObservable();
  }

  getTimetable(bearerToken: string, masterProfileId: string): Observable<StudentTimetable> {
    return this.timetableApiService.getTimetable(masterProfileId, bearerToken).pipe(tap((timetableEvents) => this.studentTimetable.next(timetableEvents)));
  }

  setSelectedEvent(event: EventImpl) {
    this.selectedEvent.next(event);
    this.popupService.setPopupOpen(!!event);
  }

  setEventOpen(isOpen: boolean) {
    this.popupService.setPopupOpen(isOpen);
  }
}
