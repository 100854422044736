import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@klg/shared/storage';
import { PathwaysAuthService, User } from '@pw/account/auth';
import { StudentNotification, StudentNotificationService } from '@pw/notification-data-access';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { PW_NOTIFICATION_PANEL_OPEN } from '@pw/shared/types';
import { AccountMenuService, NotificationPanelService } from '@pw/shared/ui';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  standalone: false,
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit, OnDestroy {
  readonly mainPage = PathwaysAccountRoutes.HOME;
  showSection = true;
  isManageAccount = false;
  currentUser = new User({});

  studentNotifications$: Observable<StudentNotification[]>;
  isPanelOpen$: Observable<boolean>;
  isSignOutVisible$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();
  private readonly studentNotificationService = inject(StudentNotificationService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly accountMenuService = inject(AccountMenuService);
  private readonly route = inject(ActivatedRoute);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly authService = inject(PathwaysAuthService);
  private readonly activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.studentNotifications$ = this.studentNotificationService.studentNotifications$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
    this.isSignOutVisible$ = this.accountMenuService.isSignOutVisible$;
  }

  ngOnInit(): void {
    this.currentUser = this.activatedRoute.snapshot?.data?.currentUser;

    const bearerToken = this.authService.getBearerToken();
    const masterProfileId = this.authService.getActiveAccountMasterProfileId();
    this.studentNotificationService.getStudentNotifications(bearerToken, masterProfileId);

    this.route.url
      .pipe(
        tap(([{ path }]) => (this.isManageAccount = PathwaysAccountRoutes.MANAGE_ACCOUNT === path)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    const isPanelOpen = this.localStorageService.getItem<boolean>(PW_NOTIFICATION_PANEL_OPEN);
    if (isPanelOpen === null) {
      localStorage.setItem(PW_NOTIFICATION_PANEL_OPEN, 'true');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
