import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Severity } from '@pw/shared/types';
import { Button } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { PwButtonComponent } from '../../atoms';

@Component({
  standalone: true,
  imports: [CommonModule, PanelModule, PwButtonComponent, NgOptimizedImage, Button],
  selector: 'pw-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
})
export class NotificationToastComponent implements OnInit {
  @Input() severity: Severity = Severity.Success;
  @Input() buttonSeverity: Severity = this.severity;
  @Input() header = '';
  @Input() showHeader = true;
  @Input() closable = false;
  @Input() primaryLabel = '';
  @Input() secondaryLabel = '';
  @Input() primaryGtmId = '';
  @Input() secondaryGtmId = '';
  @Input() flexDirection: 'row' | 'column' = 'column';
  @Output() primaryClicked = new EventEmitter<void>();
  @Output() secondaryClicked = new EventEmitter<void>();
  @Output() closeButtonClicked = new EventEmitter<void>();

  styleClass = '';
  headerIcon = '';

  ngOnInit(): void {
    this.styleClass = `notification-toast__panel--${this.severity} notification-toast__panel--flex-${this.flexDirection}`;
    // hack to bypass limitation of ngSrc (https://github.com/angular/angular/issues/47813)
    setTimeout(() => (this.headerIcon = this.severity ? `/assets/icons/${this.setHeaderIcon(this.severity)}` : ''));
  }

  private setHeaderIcon(severity: Severity) {
    switch (severity) {
      case Severity.Success:
        return 'checkmark-filled.svg';
      case Severity.Warning:
        return 'warning-filled.svg';
      case Severity.Danger:
        return 'close-filled.svg';
      case Severity.Primary:
        return 'info.svg';
    }
  }
}
