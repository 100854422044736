import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  isPopupOpen$: Observable<boolean>;

  private isPopupOpen = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isPopupOpen$ = this.isPopupOpen.asObservable();
  }

  setPopupOpen(isOpen: boolean) {
    this.isPopupOpen.next(isOpen);
  }
}
