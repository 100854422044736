<ng-container *ngIf="sessions.length">
  <p-table
    [value]="sessions"
    [tableStyle]="{ 'min-width': '65rem' }"
    [paginator]="true"
    [rows]="rows"
    [totalRecords]="sessions.length"
    (onPage)="onPageChange($event)"
    [pageLinks]="maxPageLinks"
    [showFirstLastIcon]="false"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let header of tableHeaders; last as isLast">
          <div class="attendance-table__th-container">
            <span class="attendance-table__th-header">{{ header }}</span>
            <img *ngIf="!isLast" alt="Vertical line" [ngSrc]="lineVerticalPath" width="1" height="26" />
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-absence>
      <tr>
        <td>{{ absence.date | date : 'dd/MM/yyyy' }}</td>
        <td>{{ absence.startTime }} - {{ absence.endTime }}</td>
        <td>{{ absence.type }}</td>
        <td>{{ absence.name }}</td>
        <td>{{ absence.code }}</td>
        <td>
          <a [href]="'mailto:' + absence.teacherEmail">{{ absence.teacherFullName }}</a>
        </td>
        <td>{{ absence.absenceAuthorized ? 'Yes' : 'No' }}</td>
        <td>{{ absence.absenceType }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div>Showing {{ currentMin }} - {{ sessions?.length > currentMax ? currentMax : sessions?.length }} of {{ sessions?.length }} items</div>
    </ng-template>
  </p-table>
</ng-container>

<ng-container *ngIf="!sessions?.length">
  <p-table [value]="sessions" [tableStyle]="{ 'min-width': '65rem' }">
    <ng-template pTemplate="summary">
      <div class="attendance-table__no-absences">
        <img *ngIf="!isUnposted" alt="Face satisfied icon" [ngSrc]="faceSatisfiedImg" width="24" height="24" />
        <span class="attendance-table__no-absences-message">{{ noSessionsMessage }}</span>
      </div>
    </ng-template>
  </p-table>
</ng-container>
