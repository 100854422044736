import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { StudentNotificationService, TaskItemStatusEnum } from '@pw/notification-data-access';
import { environment } from '@pw/shared/environment';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../services';
import { PW_NOTIFICATION_PANEL_OPEN } from '@pw/shared/types';
import { LocalStorageService } from '@klg/shared/storage';
import { PwClickOutsideDirective } from '../../directives/click-outside/click-outside.directive';

@Component({
  selector: 'pw-menu',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, PwClickOutsideDirective],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() menuItems: MenuItem[] = [];

  taskIconImg = 'assets/icons/clip-folder.svg';
  taskIconErrorBadgeImg = 'assets/icons/warning-filled-tasks.svg';
  isNotificationsPanelOpen = false;
  chevron: PrimeIcons | undefined;
  isMenuOpen = false;
  pendingTasks = 0;
  showNotifications = environment.PRE_ARRIVAL_CHECKLIST_ENABLED;
  studentNotificationsError$: Observable<string>;

  private destroy$: Subject<void> = new Subject<void>();
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly studentNotificationService = inject(StudentNotificationService);
  private readonly localStorageService = inject(LocalStorageService);

  constructor() {
    this.studentNotificationsError$ = this.studentNotificationService.studentNotificationsError$;
  }

  ngOnInit(): void {
    this.setCloseChevron();

    this.notificationPanelService.isPanelOpen$.pipe(takeUntil(this.destroy$)).subscribe((notificationsPanelOpen) => {
      this.isNotificationsPanelOpen = notificationsPanelOpen;
    });

    this.studentNotificationService.studentNotifications$.pipe(takeUntil(this.destroy$)).subscribe((userNotifications) => {
      const pendingTasks = (this.studentNotificationService.getPreArrivalTasks()?.tasks?.filter((task) => task.status !== TaskItemStatusEnum.completed) || [])
        ?.length;
      const pendingPayments = (this.studentNotificationService.getPaymentReminders()?.payments || [])?.reduce(
        (acc, payment) => acc + payment.itemsOverDue + payment.itemsDueSoon,
        0,
      );

      this.pendingTasks = pendingTasks + pendingPayments;
      // INFO: If panel is not open but there are new notifications and the user didn't close it previously, open panel
      const isPanelOpen = this.localStorageService.getItem<boolean>(PW_NOTIFICATION_PANEL_OPEN);
      if (!this.isNotificationsPanelOpen && userNotifications.length > 0 && isPanelOpen) {
        this.notificationPanelService.togglePanel(true);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      this.setOpenChevron();
    } else {
      this.setCloseChevron();
    }
  }

  toggleNotificationPanel() {
    if (this.isMenuOpen) {
      this.toggleMenu();
    }

    this.notificationPanelService.isPanelOpen$
      .pipe(
        take(1),
        tap((isPanelOpen) => this.notificationPanelService.togglePanel(!isPanelOpen)),
      )
      .subscribe();
  }

  executeMenuItemCommand(menuItem: MenuItem): void {
    menuItem.command();
    this.toggleMenu();
  }

  onOutsideClick() {
    if (this.isMenuOpen) {
      this.toggleMenu();
    }
  }

  private setCloseChevron(): void {
    this.chevron = PrimeIcons.CHEVRON_DOWN;
  }

  private setOpenChevron(): void {
    this.chevron = PrimeIcons.CHEVRON_UP;
  }
}
