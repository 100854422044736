export enum PathwaysAccountRoutes {
  ERROR = 'error',
  NOT_FOUND = 'not-found',
  HOME = 'home',
  LOCAL_SIGN_OUT = 'local-sign-out',
  MANAGE_ACCOUNT = 'manage-account',
  PASSWORD_CHANGED = 'password-changed',
  SIGN_IN = 'sign-in',
  SIGN_IN_FAIL = 'sign-in-failed',
  SIGN_OUT = 'sign-out',
  SIGN_UP = 'sign-up',
  TERMS_OF_USE = 'terms-of-use',
  VALIDATION = 'validation',
  REDIRECTS = 'redirects',
  MAINTENANCE_MODE = 'maintenance',
  ATTENDANCE_OVERVIEW = 'attendance-overview',
  MULTI_STEP_CONFIRMATION = 'multi-step-confirmation',
  PHONE_NUMBER_CHANGED = 'phone-number-changed',
  TIMETABLE_PAGE = 'timetable',
}

export function isPrivateRoute(route: string): boolean {
  return route.endsWith(PathwaysAccountRoutes.HOME) || route.endsWith(PathwaysAccountRoutes.MANAGE_ACCOUNT);
}

export enum PathwaysAccountRouteParams {
  ERROR_CODE = 'errorCode',
  ID = 'id',
  TOKEN = 'token',
  TYPE = 'type',
}
