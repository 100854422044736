<p-carousel
  #carousel
  [value]="attendanceStats"
  [numVisible]="1"
  [numScroll]="1"
  [showIndicators]="false"
  [circular]="true"
  [page]="defaultPage"
  (onPage)="onPageChange($event)"
>
  <ng-template let-stat pTemplate="item">
    <div class="attendance-carousel__item" (click)="goToAttendanceOverview(stat)">
      <pw-attendance-percentage [attendanceStat]="stat" [showFilter]="!isHome && stat.frequency !== attendanceType.overall"></pw-attendance-percentage>
    </div>
  </ng-template>
</p-carousel>
