import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountMenuService {
  isSignOutVisible$: Observable<boolean>;

  private isSignOutVisible = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isSignOutVisible$ = this.isSignOutVisible.asObservable();
  }

  hideSignOut(): void {
    this.isSignOutVisible.next(false);
  }

  showSignOut(): void {
    this.isSignOutVisible.next(true);
  }
}
