import { inject, Injectable } from '@angular/core';
import { Memoize } from '@klg/shared/utils-http';
import { SAAttendancesApiService } from '@pw/api/profile';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, catchError, EMPTY, Observable, tap } from 'rxjs';
import {
  areDatesEqual,
  createDateFromString,
  formatMonthlyDates,
  formatOverallDates,
  formatWeeklyDates,
  parseMonthlyDate,
  parseWeeklyDates,
} from '../functions';
import { AttendanceStat, AttendanceSummary, AttendanceTypeEnum, StudentAttendance } from '../models';
import { subDays } from 'date-fns';

export type AttendanceStats = {
  stats: AttendanceStat[];
  lastModifiedAt: Date | null;
};

@Injectable({
  providedIn: 'root',
})
export class StudentAttendanceService {
  studentAttendance$: Observable<StudentAttendance>;
  attendanceStats$: Observable<AttendanceStats>;
  currentMonthlyFilters$: Observable<Date[]>;
  currentWeeklyFilters$: Observable<Date[]>;
  activeTabIndex$: Observable<number>;
  attendancesError$: Observable<string>;

  monthlyFilters: Date[][] = [];
  weeklyFilters: Date[][] = [];

  private lastModifiedAt: Date | null = null;
  private studentAttendance = new BehaviorSubject<StudentAttendance>(null);
  private attendanceStats = new BehaviorSubject<AttendanceStats>({
    stats: [],
    lastModifiedAt: null,
  });
  private currentMonthlyFilters = new BehaviorSubject<Date[]>([]);
  private currentWeeklyFilters = new BehaviorSubject<Date[]>([]);
  private activeTabIndex = new BehaviorSubject<number>(0);
  private attendancesError = new BehaviorSubject<string>(undefined);

  private readonly attendanceService = inject(SAAttendancesApiService);

  constructor() {
    this.studentAttendance$ = this.studentAttendance.asObservable();
    this.attendanceStats$ = this.attendanceStats.asObservable();
    this.currentMonthlyFilters$ = this.currentMonthlyFilters.asObservable();
    this.currentWeeklyFilters$ = this.currentWeeklyFilters.asObservable();
    this.activeTabIndex$ = this.activeTabIndex.asObservable();
    this.attendancesError$ = this.attendancesError.asObservable();
  }

  @Memoize((masterProfileId: string) => masterProfileId)
  getAttendances(bearerToken: string, masterProfileId: string): Observable<StudentAttendance> {
    return this.attendanceService.getAttendances(masterProfileId, bearerToken).pipe(
      tap((studentAttendance) => {
        this.lastModifiedAt = studentAttendance.modifiedAt ? new Date(studentAttendance.modifiedAt) : subDays(new Date(), 1);
        this.studentAttendance.next(studentAttendance);
        this.setInitialStats(studentAttendance);
        this.setFilters(studentAttendance);
      }),
      catchError((error: Error) => {
        console.error('Error occurred while getting attendances', error);
        this.attendancesError.next(error.message);
        return EMPTY as Observable<StudentAttendance>;
      }),
    );
  }

  setCurrentFilters(frequency: string, formattedDate: string) {
    let filters: Date[];
    switch (frequency) {
      case AttendanceTypeEnum.monthly:
        filters = parseMonthlyDate(formattedDate);
        this.currentMonthlyFilters.next(filters);
        break;
      case AttendanceTypeEnum.weekly:
        filters = parseWeeklyDates(formattedDate);
        this.currentWeeklyFilters.next(filters);
        break;
    }
    this.setCurrentStats(filters, frequency);
  }

  getMenuItemsByFrequency(frequency: string): MenuItem[] {
    switch (frequency) {
      case AttendanceTypeEnum.monthly:
        return this.monthlyFilters.map((value) => ({ label: formatMonthlyDates(value) }));
      case AttendanceTypeEnum.weekly:
        return this.weeklyFilters.map((value) => ({ label: formatWeeklyDates(value) }));
    }
  }

  setTabIndex(index: number) {
    this.activeTabIndex.next(index);
  }

  private setInitialStats(studentAttendance: StudentAttendance) {
    studentAttendance.monthly.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
    const mostRecentMonthlyStats = studentAttendance.monthly[0];
    const monthly: AttendanceStat = {
      frequency: AttendanceTypeEnum.monthly,
      percentage: mostRecentMonthlyStats?.attendance,
      filterDates: formatMonthlyDates([mostRecentMonthlyStats?.startDate]),
      hasAbsence: mostRecentMonthlyStats?.absences.length > 0,
    };

    studentAttendance.weekly.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
    const mostRecentWeeklyStats = studentAttendance.weekly[0];
    const weekly: AttendanceStat = {
      frequency: AttendanceTypeEnum.weekly,
      percentage: mostRecentWeeklyStats?.attendance,
      filterDates: formatWeeklyDates([mostRecentWeeklyStats?.startDate, mostRecentWeeklyStats?.endDate]),
      hasAbsence: mostRecentWeeklyStats?.absences.length > 0,
    };

    const overall: AttendanceStat = {
      frequency: AttendanceTypeEnum.overall,
      percentage: studentAttendance.overall.attendance,
      filterDates: formatOverallDates([studentAttendance.overall.startDate, studentAttendance.overall.endDate]),
      hasAbsence: studentAttendance.overall.absences.length > 0,
    };

    const noDataAvailable = studentAttendance.overall.attendance === 0 && studentAttendance.overall.absences.length === 0;
    const newStats = noDataAvailable ? [] : [weekly, monthly, overall];

    this.attendanceStats.next({
      stats: newStats,
      lastModifiedAt: this.lastModifiedAt,
    });
  }

  private setFilters(studentAttendance: StudentAttendance) {
    this.monthlyFilters = studentAttendance.monthly.map((attendanceSummary) => {
      const startDate = createDateFromString(attendanceSummary.startDate);
      const endDate = createDateFromString(attendanceSummary.endDate);
      return [startDate, endDate];
    });
    this.weeklyFilters = studentAttendance.weekly.map((attendanceSummary) => {
      const startDate = createDateFromString(attendanceSummary.startDate);
      const endDate = createDateFromString(attendanceSummary.endDate);
      return [startDate, endDate];
    });
    this.currentMonthlyFilters.next(this.monthlyFilters[0]);
    this.currentWeeklyFilters.next(this.weeklyFilters[0]);
  }

  private setCurrentStats(dates: Date[], frequency: string) {
    let attendanceSummary: AttendanceSummary;
    const newStat = {} as AttendanceStat;
    const currentStats = this.attendanceStats.value.stats;
    switch (frequency) {
      case AttendanceTypeEnum.weekly:
        attendanceSummary = this.studentAttendance.value.weekly.find((summary) => areDatesEqual(summary.startDate, dates[0]));
        if (attendanceSummary) {
          newStat.frequency = AttendanceTypeEnum.weekly;
          newStat.percentage = attendanceSummary.attendance;
          newStat.filterDates = formatWeeklyDates([attendanceSummary.startDate, attendanceSummary.endDate]);
          newStat.hasAbsence = attendanceSummary.absences.length > 0;
        }
        break;
      case AttendanceTypeEnum.monthly:
        attendanceSummary = this.studentAttendance.value.monthly.find((summary) => areDatesEqual(summary.startDate, dates[0]));
        if (attendanceSummary) {
          newStat.frequency = AttendanceTypeEnum.monthly;
          newStat.percentage = attendanceSummary.attendance;
          newStat.filterDates = formatMonthlyDates([attendanceSummary.startDate, attendanceSummary.endDate]);
          newStat.hasAbsence = attendanceSummary.absences.length > 0;
        }
        break;
    }
    const newStats = currentStats.map((stat) => (stat.frequency === frequency ? newStat : stat));
    this.attendanceStats.next({
      stats: newStats,
      lastModifiedAt: this.lastModifiedAt,
    });
  }
}
