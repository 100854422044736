import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '@klg/shared/storage';
import { StudentNotificationService } from '@pw/notification-data-access';
import { PW_NOTIFICATION_PANEL_OPEN } from '@pw/shared/types';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { Observable, Subject, take, tap } from 'rxjs';
import { PwClickOutsideDirective } from '../../directives/click-outside/click-outside.directive';
import { NotificationPanelService } from '../../services';

@Component({
  selector: 'pw-menu',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, PwClickOutsideDirective],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() menuItems: MenuItem[] = [];

  taskIconImg = 'assets/icons/clip-folder.svg';
  taskIconErrorBadgeImg = 'assets/icons/warning-filled-tasks.svg';
  chevron: PrimeIcons | undefined;
  isMenuOpen = false;
  pendingNotifications = 0;
  isPanelOpen$: Observable<boolean>;
  studentNotificationsError$: Observable<string>;

  private destroy$: Subject<void> = new Subject<void>();
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly studentNotificationService = inject(StudentNotificationService);
  private readonly localStorageService = inject(LocalStorageService);

  constructor() {
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
    this.studentNotificationsError$ = this.studentNotificationService.studentNotificationsError$;
  }

  ngOnInit(): void {
    this.setCloseChevron();

    this.pendingNotifications = this.studentNotificationService.getPendingTasks() + this.studentNotificationService.getPendingPayments();
    // INFO: If panel is not open but there are new notifications and the user didn't close it previously, open panel
    const wasPanelOpen = this.localStorageService.getItem<boolean>(PW_NOTIFICATION_PANEL_OPEN);
    if (wasPanelOpen && this.pendingNotifications > 0) {
      this.notificationPanelService.togglePanel(true);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      this.setOpenChevron();
    } else {
      this.setCloseChevron();
    }
  }

  toggleNotificationPanel() {
    if (this.isMenuOpen) {
      this.toggleMenu();
    }

    this.notificationPanelService.isPanelOpen$
      .pipe(
        take(1),
        tap((isPanelOpen) => this.notificationPanelService.togglePanel(!isPanelOpen)),
      )
      .subscribe();
  }

  executeMenuItemCommand(menuItem: MenuItem): void {
    menuItem.command();
    this.toggleMenu();
  }

  onOutsideClick() {
    if (this.isMenuOpen) {
      this.toggleMenu();
    }
  }

  private setCloseChevron(): void {
    this.chevron = PrimeIcons.CHEVRON_DOWN;
  }

  private setOpenChevron(): void {
    this.chevron = PrimeIcons.CHEVRON_UP;
  }
}
