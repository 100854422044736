<pw-section-shell sectionHeader="Timetable" [headerIsAnchor]="false">
  <ng-container *ngIf="studentTimetable$ | async as studentTimetable; else loading">
    <span *ngIf="studentTimetable.modifiedAt as lastModifiedAt">Last updated on {{ lastModifiedAt | date: 'd MMMM YYYY, HH:mm a' }}</span>
    <pw-timetable [timetable]="studentTimetable.timetable"></pw-timetable>
  </ng-container>

  <ng-template #loading>
    <p-skeleton width="40rem" height="1rem" styleClass="mb-2" />
    <p-skeleton width="40rem" height="2rem" styleClass="mb-2" />
    <p-skeleton width="40rem" height="3rem" styleClass="mb-2" />
  </ng-template>
</pw-section-shell>
