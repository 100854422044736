import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PaymentItem } from '@pw/notification-data-access';
import { PaymentsDueBoxComponent } from '../../molecules';

@Component({
  selector: 'pw-payments-due-notification',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, PaymentsDueBoxComponent],
  templateUrl: './payments-due-notification.component.html',
  styleUrls: ['./payments-due-notification.component.scss'],
})
export class PaymentsDueNotificationComponent {
  @Input() payments: PaymentItem[] = [];

  editImg = '/assets/icons/edit.svg';

  readonly supportEmail = 'pathwaysukaccounts@kaplan.com';
}
