import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, Component, inject, Input } from '@angular/core';
import { AttendanceSession } from '@pw/profile-data-access';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { AttendanceTableComponent } from '../../atoms';

@Component({
  selector: 'pw-attendance-absences-tabs',
  standalone: true,
  imports: [CommonModule, TabViewModule, AttendanceTableComponent, NgOptimizedImage, TooltipModule],
  templateUrl: './attendance-absences-tabs.component.html',
  styleUrls: ['./attendance-absences-tabs.component.scss'],
})
export class AttendanceAbsencesTabsComponent implements AfterViewInit {
  @Input() absences: AttendanceSession[] = [];
  @Input() unposted: AttendanceSession[] = [];

  infoIconPath = '/assets/icons/info.svg';
  infoIconTooltip = 'Attendance or absences from classes where attendance lists haven’t been uploaded or updated yet.';

  private readonly document = inject(DOCUMENT);

  ngAfterViewInit(): void {
    const emptyTab = this.document.querySelector('.attendance-absences-tabs__empty-tab');
    if (emptyTab) {
      emptyTab.removeChild(emptyTab.firstChild);
    }
  }
}
