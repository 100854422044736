import {
  AccommodationApiDto,
  AvailableAppApiDto,
  EnrollmentEntityApiDto,
  PreArrivalApiDto,
  ReadProfileResponseDtoApiDto,
  StudentFeaturesEntityApiDto,
} from '@pw/api/profile';

export type StudentProfile = ReadProfileResponseDtoApiDto;
export type AvailableApp = AvailableAppApiDto;
export type PreArrival = PreArrivalApiDto;
export type Accommodation = AccommodationApiDto;
export type Enrollment = EnrollmentEntityApiDto;
export type StudentFeature = StudentFeaturesEntityApiDto;
export type StudentFeatureEnum = StudentFeaturesEntityApiDto.IdApiDtoEnum;
export const StudentFeatureEnum = StudentFeaturesEntityApiDto.IdApiDtoEnum;
