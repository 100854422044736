import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@klg/shared/storage';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { User } from '@pw/account/auth';
import { StudentNotification, StudentNotificationEnum, StudentNotificationService } from '@pw/notification-data-access';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { PopupService } from '@pw/shared/services';
import { PW_NOTIFICATION_PANEL_OPEN } from '@pw/shared/types';
import { AccountMenuService, NotificationPanelService } from '@pw/shared/ui';
import { combineLatest, filter, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  templateUrl: './base-page.component.html',
  standalone: false,
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit, OnDestroy {
  readonly mainPage = PathwaysAccountRoutes.HOME;
  showSection = true;
  isManageAccount = false;
  currentUser = new User({});
  infoNotifications: StudentNotification[] = [];

  studentNotifications$: Observable<StudentNotification[]>;
  isPanelOpen$: Observable<boolean>;
  isConfirmUkAddressOpen$: Observable<boolean>;
  isSignOutVisible$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();
  private readonly studentNotificationService = inject(StudentNotificationService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly accountMenuService = inject(AccountMenuService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly route = inject(ActivatedRoute);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly popupService = inject(PopupService);

  constructor(private readonly activatedRoute: ActivatedRoute) {
    this.studentNotifications$ = this.studentNotificationService.studentNotifications$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
    this.isConfirmUkAddressOpen$ = this.notificationPanelService.isConfirmUkAddressOpen$;
    this.isSignOutVisible$ = this.accountMenuService.isSignOutVisible$;
    this.isMobile$ = this.breakpointObserverService.isMobile$;
  }

  ngOnInit(): void {
    this.currentUser = this.activatedRoute.snapshot?.data?.currentUser;

    this.route.url
      .pipe(
        tap(([{ path }]) => (this.isManageAccount = PathwaysAccountRoutes.MANAGE_ACCOUNT === path)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    combineLatest([this.popupService.isPopupOpen$, this.isMobile$])
      .pipe(tap(([isPopupOpen, isMobile]) => (this.showSection = !(isMobile && isPopupOpen))))
      .subscribe();

    this.studentNotifications$
      .pipe(
        tap((notifications) => (this.infoNotifications = notifications.filter((notification) => notification.type === StudentNotificationEnum.info))),
        takeUntil(this.destroy$),
      )
      .subscribe();

    combineLatest([this.isMobile$, this.isSignOutVisible$])
      .pipe(
        filter(([isMobile, isSignOutVisible]) => isMobile && isSignOutVisible),
        tap(() => {
          this.notificationPanelService.togglePanel(false, true);
          this.notificationPanelService.toggleConfirmUkAddress(false);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    const isPanelOpen = this.localStorageService.getItem<boolean>(PW_NOTIFICATION_PANEL_OPEN);
    if (isPanelOpen === null) {
      localStorage.setItem(PW_NOTIFICATION_PANEL_OPEN, 'true');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
