import { inject, Injectable } from '@angular/core';
import { PopupService } from '@pw/shared/services';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountMenuService {
  isSignOutVisible$: Observable<boolean>;

  private isSignOutVisible = new BehaviorSubject<boolean>(false);

  private readonly popupService = inject(PopupService);

  constructor() {
    this.isSignOutVisible$ = this.isSignOutVisible.asObservable();
  }

  hideSignOut(): void {
    this.isSignOutVisible.next(false);
    this.popupService.setPopupOpen(false);
  }

  showSignOut(): void {
    this.isSignOutVisible.next(true);
    this.popupService.setPopupOpen(true);
  }
}
