<pw-card-shell>
  <ng-container *ngIf="showCarousel; else isDesktop">
    <pw-attendance-carousel [attendanceStats]="attendanceStats"></pw-attendance-carousel>
  </ng-container>

  <ng-template #isDesktop>
    <div class="attendance-card__desktop">
      <ng-container *ngFor="let stat of attendanceStats; let isLast = last; let isFirst = first">
        <div class="attendance-card__container" [ngClass]="{ first: isFirst, last: isLast }" (click)="goToAttendanceOverview(stat)">
          <pw-attendance-percentage class="attendance-card__percentage" [attendanceStat]="stat"></pw-attendance-percentage>
        </div>
      </ng-container>
    </div>
  </ng-template>
</pw-card-shell>
