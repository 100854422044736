import { AttendanceSessionApiDto, AttendanceSummaryApiDto, ResultApiDto } from '@pw/api/profile';

export type AttendanceResult = ResultApiDto;
export type AttendanceSession = AttendanceSessionApiDto;
export type AttendanceSummary = AttendanceSummaryApiDto;

export type AttendanceTypeEnum = 'weekly' | 'monthly' | 'overall';
export const AttendanceTypeEnum = {
  weekly: 'weekly' as AttendanceTypeEnum,
  monthly: 'monthly' as AttendanceTypeEnum,
  overall: 'overall' as AttendanceTypeEnum,
};

export interface AttendanceStat {
  frequency: AttendanceTypeEnum;
  percentage: number;
  filterDates: string;
  hasAbsence: boolean;
}
