import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';
import { SignOutType, Size } from '@pw/shared/types';
import { SharedModule } from 'primeng/api';
import { AccountMenuService } from '../../../services';
import { PwButtonComponent } from '../../atoms';
import { PopupShellComponent } from '../../molecules';

@Component({
  standalone: true,
  imports: [CommonModule, PopupShellComponent, PwButtonComponent, SharedModule],
  selector: 'pw-sign-out-popup',
  templateUrl: './sign-out-popup.component.html',
  styleUrls: ['./sign-out-popup.component.scss'],
})
export class SignOutPopupComponent {
  @Output() cancelButtonClicked = new EventEmitter<void>();

  protected readonly buttonSize = Size;

  private readonly authService = inject(PathwaysAuthService);
  private readonly accountMenuService = inject(AccountMenuService);

  signOut(): void {
    this.authService.logout(SignOutType.DEFAULT);
  }

  hideSignOut() {
    this.accountMenuService.hideSignOut();
  }
}
