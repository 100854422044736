import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { PatchNotificationRequestAccommodationTypeEnum } from '@pw/notification-data-access';
import { Size } from '@pw/shared/types';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { take } from 'rxjs';
import { PwButtonComponent } from '../../atoms';
import { ConfirmAddressOption } from '../confirm-uk-address.component';
import { RadioButtonsComponent } from './radio-buttons/radio-buttons.component';

@Component({
  selector: 'pw-confirm-uk-address-content',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RadioButtonsComponent, BreadcrumbModule, PwButtonComponent],
  templateUrl: './confirm-uk-address-content.component.html',
  styleUrls: ['./confirm-uk-address-content.component.scss'],
})
export class ConfirmUkAddressContentComponent implements OnInit {
  @Input() isFirstStep = true;
  @Input() firstStepOptions: ConfirmAddressOption[] = [];
  @Input() secondStepOptions: ConfirmAddressOption[] = [];
  @Output() isFirstStepChange = new EventEmitter<boolean>();
  @Output() cancelButtonClicked = new EventEmitter<void>();
  @Output() confirmButtonClicked = new EventEmitter<[ConfirmAddressOption, ConfirmAddressOption, boolean]>();

  arrowLeftImg = 'assets/icons/arrow-left.svg';
  firstOptionSelected: ConfirmAddressOption | undefined;
  secondOptionSelected: ConfirmAddressOption | undefined;
  isMobile = true;

  protected readonly buttonSize = Size;

  private readonly breakpointObserverService = inject(BreakpointObserverService);

  ngOnInit(): void {
    this.breakpointObserverService.isMobile$.pipe(take(1)).subscribe((isMobile) => (this.isMobile = isMobile));

    if (this.isFirstStep) {
      this.secondOptionSelected = undefined;
    }
  }

  backToFirstStep() {
    this.isFirstStep = true;
    this.secondOptionSelected = undefined;
  }

  handleConfirm() {
    if (
      this.firstOptionSelected.value === PatchNotificationRequestAccommodationTypeEnum.book_kaplan_living_accommodation ||
      this.firstOptionSelected.value === PatchNotificationRequestAccommodationTypeEnum.booked_kaplan_living_accommodation
    ) {
      this.confirmButtonClicked.emit([this.firstOptionSelected, undefined, true]);
    } else if (!this.secondOptionSelected) {
      this.isFirstStep = false;
      this.isFirstStepChange.emit(this.isFirstStep);
      this.confirmButtonClicked.emit([this.firstOptionSelected, undefined, false]);
    } else {
      this.confirmButtonClicked.emit([this.firstOptionSelected, this.secondOptionSelected, true]);
    }
  }
}
