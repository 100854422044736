import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, inject, OnDestroy, OnInit } from '@angular/core';
import { $localize } from '@angular/localize/init';
import { AttendanceSession } from '@pw/profile-data-access';
import { TableModule } from 'primeng/table';
import { Subject, takeUntil, tap } from 'rxjs';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
interface PageEvent {
  first: number;
  rows: number;
}

@Component({
  selector: 'pw-attendance-table',
  standalone: true,
  imports: [CommonModule, TableModule, NgOptimizedImage],
  templateUrl: './attendance-table.component.html',
  styleUrls: ['./attendance-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendanceTableComponent implements OnChanges, OnInit, OnDestroy {
  @Input() sessions: AttendanceSession[] = [];
  @Input() noSessionsMessage = $localize`You're doing great! Nothing to display`;

  isUnposted = false;
  faceSatisfiedImg = 'assets/icons/face-satisfied.svg';
  tableHeaders = ['Date', 'Time', 'Type', 'Name', 'Code', 'Teacher Name', 'Authorised', 'Absence reason'];
  lineVerticalPath = 'assets/icons/line-vertical.svg';
  rows = 10;
  maxPageLinks = 5;
  currentMin = 1;
  currentMax = this.rows;
  isMobile = false;

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly destroy$ = new Subject<void>();

  onPageChange(event: PageEvent) {
    this.currentMin = event.first + 1;
    this.currentMax = event.first + event.rows;
  }

  ngOnInit(): void {
    this.breakpointObserverService.isMobile$
      .pipe(
        tap((isMobile) => {
          this.isMobile = isMobile;
          this.maxPageLinks = isMobile ? 3 : 5;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges({ noSessionsMessage }: SimpleChanges): void {
    if (noSessionsMessage?.currentValue) {
      this.isUnposted = true;
    }
  }
}
