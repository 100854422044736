/**
 * Profile API
 * Profile
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface StudentFeaturesEntityApiDto {
  enabled: boolean;
  /**
   * Feature ID
   */
  id: StudentFeaturesEntityApiDto.IdApiDtoEnum;
}
export namespace StudentFeaturesEntityApiDto {
  export type IdApiDtoEnum = 'attendance' | 'college_logo';
  export const IdApiDtoEnum = {
    attendance: 'attendance' as IdApiDtoEnum,
    college_logo: 'college_logo' as IdApiDtoEnum,
  };
}
