import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PaymentItem } from '@pw/notification-data-access';
import { PaymentType, Severity } from '@pw/shared/types';
import { PaymentsDueComponent, PwButtonComponent } from '../../atoms';
import { environment } from '@pw/shared/environment';

@Component({
  selector: 'pw-payments-due-box',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, PaymentsDueComponent, PwButtonComponent],
  templateUrl: './payments-due-box.component.html',
  styleUrls: ['./payments-due-box.component.scss'],
})
export class PaymentsDueBoxComponent implements OnChanges {
  @Input() payment: PaymentItem | undefined;
  @Input() isFirst = false;

  collegeLogo = '';
  paymentType = PaymentType;
  severity = Severity;
  oppUrl = environment.STUDENT_APPS_URLS.OPP;

  ngOnChanges({ payment }: SimpleChanges): void {
    if (payment?.currentValue?.collegeCode) {
      this.collegeLogo = 'assets/college-logos/' + this.payment.collegeCode + '.svg';
    }
  }
}
